// custom Callout Box styles
.callout-box {
  box-sizing: border-box;
  width: 100%;
  clear: both;
  display: block;
  border: 0.5px solid $swab-gray;
  background: $swab-pale-blue;
  padding: $horiz-gutter $horiz-gutter $horiz-gutter/3;
  margin: $horiz-gutter 0;
  ul li:before {
    background-color: black;
  }
}

// custom Sidebar Box styles
.sidebar-box {
  float: right;
  width: 100%;
  border-top: 2px solid $swab-gray;
  padding-top: $horiz-gutter/2;
  ul {
    margin-left: 14px;
    li:before {
      background-color: black;
    }
  }
  h3 {
    margin-bottom: 10px;
  }
  h4 {
    margin-bottom: 10px;
    color: $swab-blue;
    font-weight: normal;
    font-family: $body-font;
    font-size: $h4-font-size;
  }
  @include breakpoint($phone-min) {
    max-width: 215px;
    margin-left: $horiz-gutter;
  }
}

// .field-name-body .field-item > * {
//   @include breakpoint($phone-min) {
//     clear: none;
//   }
// }

body:not(.front) {
  @include breakpoint($tablet-portrait-max) {
    #content-wrapper {
      #content-column,
      .sidebar {
        width: calc(100% - 50px);
        float: none;
        display: block;
        margin: 0 auto;
  
        .block-inner {
          margin: 0;
        }
      }
    }
  }

  @include breakpoint($tablet-portrait-min) {
    #content-wrapper {
      width: calc(100% - 40px);
      display: block;
      margin: 0 auto;
    }
  }
}

.region-content > div > .block-inner {
  margin-left: $horiz-gutter;
  margin-right: $horiz-gutter;
}