.field-name-body {
  ul {
    li {
      list-style-type: none;
      position: relative;

      &::before {
        content: '';
        display: inline-block;
        width: 5px;
        height: 5px;
        margin-right: 8px;
        margin-left: -13px;
        border-radius: 50%;
        background-color: $swab-red;
        vertical-align: middle;
      }
    }

    &.links {
      li::before {
        background-color: black;
      }
    }
  }
}

// Related Topics list
.view-related-topics.view-display-id-block {
  border-top: 2px solid $swab-gray;
  margin: $horiz-gutter/2 0;
  padding-bottom: $horiz-gutter;
  padding-left: $horiz-gutter*1.25;
  padding-top: $horiz-gutter*0.6;

  h2 {
    color: $swab-dark-blue;
  }

  .item-list {
    background-color: $swab-pale-blue;
    border: 0.5px solid $swab-gray;
    padding: $horiz-gutter 0 $horiz-gutter $horiz-gutter*1.25;
  }

  ul {
    li {
      padding-bottom: 6px;

      a {
        color: $swab-red;
        display: block;
      }
    }
  }
}

// Booklist cards
.book_blurb {
  padding: $vert-gutter/2;
  background: white;
  color: $swab-black;
  border-radius: 0 0 4px 4px;

  * {
    font-size: 0.875rem;
  }

  h2 {
    margin: 8px 0 4px;
    padding-bottom: 10px;
    text-decoration: none;
    font-size: 1.1rem;
    font-weight: 700;
    color: $swab-dark-blue !important;
  }

  .author {
    span,
    div {
      display: inline;
    }
  }

  .field {
    margin-bottom: 0;
  }

  .field-label {
    font-weight: normal;
  }

  .field > * {
    display: inline;
    margin-right: 5px;
  }
}

.book-cover-description {
  border-bottom: $thick-border;
  position: relative;
  border-radius: 4px 4px 0 0;
  min-height: 160px;
  width: 100%;
  height: auto;
}

.field-book-image {
  margin: 0;
}

.book-description-overlay {
  background: $swab-pale-blue-transparent;
  padding: $vert-gutter/2;
  font-size: 0.9rem;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  color: $swab-black;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px 4px 0 0;
  max-height: 50em;
  @include transition;

  &[aria-hidden="true"] {
    overflow: hidden;
    max-height: 0;
    top: 100%;
    padding: 0;
  }

  * {
    font-size: 0.9rem;
  }

  .purchase-links {
    margin: 20px 0 18px;

    p {
      margin: 0;

      & + p {
        margin-top: 8px;
      }
    }
  }

  a {
    @include transition;

    margin-top: $vert-gutter/4;
    margin-bottom: $vert-gutter/8;
    appearance: none;
    border: none;
    width: auto;
    text-align: left;
    margin-left: 0;
    border-radius: 0;
    padding: 0;
    font-size: 0.9rem;
    background-color: transparent;
    position: relative;
    font-weight: 600;

    &:hover,
    &:focus {
     text-decoration: none;
     color: $swab-dark-red;
    }

    i {
      margin-left: 5px;
    }
  }
}

.book-info-button {
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  margin: auto;
  appearance: none;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0;
  border: 1px solid $primary-button-color;
  background: $primary-button-color;
  color: #fff;
  border-radius: 18px;
  width: 32px;
  max-width: 120px;
  z-index: 3;
  white-space: nowrap;
  overflow: hidden;
  transition: none;
  cursor: pointer;

  @media screen and (prefers-reduced-motion: no-preference) {
    @include transition($duration: 0.25s);
  }

  span {
    display: inline-block;
    line-height: 0;
    text-align: left;
    color: #fff;

    @media screen and (prefers-reduced-motion: no-preference) {
      @include transition($duration: 0.5s);
    }
  }

  i {
    font-size: 32px;
    line-height: 1;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    margin-right: -1px;
    margin-bottom: 2px;
    margin-left: -1px;
    transform: scale(1);
    position: relative;
    bottom: -2px;

    @media screen and (prefers-reduced-motion: no-preference) {
      @include transition;
    }
  }

  &:hover {
    max-width: 118px;
    width: 100%;
    padding: 0 8px 0 2px;
    border-color: $swab-black;

    i {
      transform: scale(0.5);
      margin-right: -6px;
      bottom: -1px;
    }

    span {
      line-height: 1;
      max-width: 200px;
      vertical-align: middle;
      padding-left: 2px;
    }
  }

  &:focus {
    border-color: $swab-black;
    padding: 0;
    border-radius: 18px;
    text-align: center;

    i {
      transform: scale(0.5);
      display: block;
      text-align: center;
      bottom: -1px;
    }

    span {
      max-width: 0;
      overflow: hidden;
      text-indent: -99px;
      position: absolute;
    }

    &:hover {
      max-width: 118px;
      width: 100%;
      padding: 0 8px 0 0;
      border-color: $swab-black;
      display: inline-block;

      i {
        display: inline-block;
      }

      span {
        max-width: 200px;
        vertical-align: middle;
        padding-left: 4px;
        opacity: 1;
        text-indent: 0;
        position: relative;
      }
    }
  }
}
