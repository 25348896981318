// Components and elements that make up the site masthead, inside the <header> element

// - Leaderboard
//   - WETA logo
//   - Search block
//   - Donate button
// - Header
//   - Logo/home button

[id="header-main"] {
  display: flex;
  justify-content: space-between;
  position: relative;

  @include breakpoint($desktop-medium-min $menu-stacked-max) {
    justify-content: flex-end;
  }

  .logo {
    width: 155px;
    height: auto;
    background-image: url("../images/logo-mobile.svg");
    background-size: contain;
    background-position: left;
    background-repeat: no-repeat;

    @include breakpoint($desktop-medium-min) {
      width: 215px;
      background-image: url("../images/logo.svg");
    }

    @include breakpoint($desktop-min) {
      margin-right: $vert-gutter*0.4;
      width: 200px;
    }

    @include breakpoint($desktop-medium-min $menu-stacked-max) {
      position: absolute;
      top: 10px;
      left: 0;
      width: 215px;
      height: 75px;
      z-index: 15;

      .logged-in & {
        top: 35px;
      }
    }

    @include breakpoint($desktop-large-min) {
      width: 240px;
    }
  }

  .header-content {
    display: flex;
  }
}

.header {
  padding-left: $horiz-gutter*0.75;
  padding-right: $horiz-gutter*0.75;

  @include breakpoint($desktop-medium-max) {
    padding-right: 0;
  }

  .region-leaderboard {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: $horiz-gutter*0.5 $horiz-gutter*0.5 0;

    @include breakpoint($desktop-medium-min) {
      justify-content: flex-end;
      padding: 0;
    }

    .block {
      .block-inner {
        padding: 0;
        margin: 0;
      }
    }

    #block-menu-menu-mini {
      width: auto;

      li {
        border: none;

        a {
          text-decoration: none;
        }
      }

      @include breakpoint($desktop-medium-max) {
        order: 3;
        width: 100%;

        .menu {
          display: flex;
          justify-content: center;
          margin-top: $horiz-gutter;
          margin-bottom: $horiz-gutter;

          &::after {
            display: none;
          }
        }

        li {
          margin: 0 $horiz-gutter*0.5;
          padding: 0;
        }

        a {
          font-size: 1rem;
          color: #fff;
        }
      }

      @include breakpoint($desktop-medium-min) {
        a {
          @include hover-underline;
          padding: $horiz-gutter*0.25 0;
          color: $swab-black;
        }
      }
    }

    #block-block-23 {
      width: auto;
    }

    #block-block-2 {
      width: auto;
      right: 0;
      margin: 0;
    }

    #weta {
      width: 60px;
      min-height: 30px;

      img {
        object-fit: contain;
        width: 100%;
        height: 100%;
        padding: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      @include breakpoint($desktop-medium-min) {
        a {
          @include hover-underline;
          padding: 8px 0;
          position: initial;
        }
      }
    }

    #weta, #block-menu-menu-mini,
    #block-block-41 {
      margin: 0;
    }

    .donate-button {
      @include button--submit($btn-color: $swab-red);
      @include transition;
      text-transform: capitalize;
      font-weight: bold;
      border-radius: 8px;
      border: 3px solid $swab-red;
      line-height: inherit;
      text-shadow: none;
      font-family: $title-font;
      text-decoration: none;

      .external-link-icon {
        position: relative;
        padding-right: 1em;
        width: 1em;
        height: 1em;
        line-height: 1em;
        overflow: hidden;
        display: inline-block;
        white-space: nowrap;
        text-indent: 2em;
        top: 0.2em;
        padding-right: 6px;
        vertical-align: text-top;

        i {
          font-family: "Font Awesome 6 Pro";
          font-size: inherit;
          font-weight: normal;
          position: absolute;
          top: 0;
          right: 0;
        }
      }

      &:hover,
      &:focus {
        color: $swab-black;
        box-shadow: none;
        text-shadow: none;
        background-color: #fff;
        border-color: $swab-red;
        text-decoration: underline;
      }
    }

    @include breakpoint($desktop-medium-min) {
      margin-top: $horiz-gutter*0.3333;

      .block + .block {
        margin-left: $horiz-gutter*1.5;
      }

      .block-menu,
      .donate-button {
        margin-left: $horiz-gutter*0.75 !important;
      }
    }
  }

  .header-regions {
    @include breakpoint($desktop-medium-min $menu-stacked-max) {
      width: 100%;
    }

    .header-top-region {
      @include breakpoint($desktop-medium-min $menu-stacked-max) {
        padding-top: $horiz-gutter*0.5;
        padding-bottom: $horiz-gutter;
      }
    }
  }

  // content finder search bar in header
  [id="block-search-form"] {
    margin-left: 0 !important;
    margin-bottom: 0;

    input::placeholder {
      overflow: visible;
    }

    @include breakpoint($desktop-min) {
      margin-left: $horiz-gutter * 0.675;

      input[type="search"] {
        font-size: 1rem;
      }
    }

    @include breakpoint($desktop-medium-min) {
      form {
        position: relative;
        display: flex;
        align-items: center;
        margin: 0;
      }

      .form-item-search {
        position: relative;
      }

      .js-form-item,
      .form-actions {
        margin-top: 0;
      }

      label {
        @include visually-hidden;
      }

      input[type="search"] {
        border: 0;
        border-radius: 0;
        width: 60px;
        padding: 11px 0 9px 0;
        line-height: 1;
        border-bottom: 2px solid transparent;
        @include transition;

        &::placeholder {
          color: $body-font-color;
          font-weight: 700;
        }

        &:hover,
        &:focus {
          box-shadow: none;
          border-color: $swab-blue;
        }

        &:focus {
          width: 200px;
        }
      }

      .form-actions {
        position: relative;
        width: 34px;
        height: 34px;

        &::before {
          color: $swab-blue;
          content: "\f002";
          font-family: 'FontAwesome';
          font-weight: 400;
          background: transparent;
          pointer-events: none;
          font-size: 0.875rem;
          position: absolute;
          z-index: 3;
          height: 34px;
          width: 34px;
          line-height: 36px;
          text-align: center;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }

        &:hover,
        &:focus-within {
          &::before {
            color: $swab-black;
          }
        }
      }

      input[type="submit"] {
        text-indent: -9999px;
        text-align: left;
        width: 34px;
        height: 34px;
        padding: 0;
        z-index: 2;
        background: transparent;
        border-color: transparent;
        @include transition;

        &:hover,
        &:focus {
          background-color: transparent;
          box-shadow: none;
          border-color: $swab-blue;
        }

        &:focus {
          outline: 2px dotted;
          outline-offset: 2px;
          outline-color: $swab-blue;
        }
      }
    }

    @include breakpoint($desktop-medium-min $desktop-max) {
      margin-top: 0;
      margin-left: $horiz-gutter*0.6;

      input[type="search"] {
        font-size: 0.9rem;
      }

      input[type="submit"] {
        height: 42px;
      }
    }

    @include breakpoint($desktop-medium-max) {
      display: none;

      .show-search & {
        display: block;
        position: fixed;
        top: 72px;
        left: 0;
        width: 100%;
        background: $swab-blue;
        z-index: 999;
        padding: $vert-gutter*0.5;
        background-color: $swab-pale-blue;
      }

      .show-search.logged-in & {
        top: 100px;
      }

      form {
        display: flex;
        margin: 0;
      }

      label {
        @include visually-hidden;
      }

      .form-item-search {
        margin-top: 0;
        flex: 1 1 auto;

        input {
          font-size: 16px;
          padding: $horiz-gutter*0.15 $vert-gutter*0.25;
        }
      }

      .form-actions {
        margin-left: $vert-gutter*0.25;
        margin-top: 0;
        position: relative;

        &::before {
          content: "\f002";
          color: #fff;
          font-family: 'FontAwesome';
          font-weight: 100;
          background: transparent;
          pointer-events: none;
          font-size: 0.875rem;
          position: absolute;
          z-index: 3;
          height: 0.875rem;
          width: 0.875rem;
          line-height: 1.3;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          margin: auto;
        }
      }

      div {
        width: 100%;
      }

      input[type="search"] {
        width: 88%;
        height: 37px;
      }

      input[type="submit"] {
        text-indent: -9999px;
        text-align: left;
        width: 37px;
        height: 37px;
        padding: 0;
        z-index: 2;
        border-radius: $ui-border-radius;
        background-color: $swab-blue;
        @include transition;

        &:hover,
        &:focus {
          background-color: $swab-dark-blue;
        }
      }
    }

    .header & {
      label {
        color: $body-font-color;
      }

      input[type="search"] {
        background: transparent;
        height: auto;
        // font-weight: bold;
        font-size: 1rem;
        background-color: #fff;

        &::placeholder {
          font-weight: bold;
          font-size: 1rem;
        }

        @include breakpoint($desktop-small-min $desktop-max) {
          font-size: 0.9rem;

          &::placeholder {
            font-size: 0.9rem;
          }
        }
      }
    }
  }

  [id="block-menu-menu-mini"] {
    ul a {
      font-weight: 700;
      color: $swab-black;
      display: inline-block;
      text-align: right;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }

    @include breakpoint($desktop-min) {
      ul a {
        font-size: 1rem;
      }
    }

    @include breakpoint($desktop-medium-min $desktop-max) {
      ul a {
        font-size: 0.9rem;
      }
    }

    @include breakpoint($desktop-medium-min) {
      margin-left: $horiz-gutter;

      [id="mega-menu"] > ul {
        > li > a {
          padding: 8px 0;
          @include hover-underline;

          &:hover,
          &:focus {
            &::after {
              width: 100%;
            }
          }

          &.is-active {
            &::after {
              width: 1em;
            }

            &:hover,
            &:focus {
              &::after {
                width: 100%;
              }
            }
          }

          @include breakpoint($desktop-medium-max) {
            font-size: 1.3rem;
          }
        }
      }
    }
  }
}

// Mobile Menu Toggle and behavior
.toggle-menu {
  right: 0;
  margin-left: 0;
  border: none;
  background: transparent;

  .bars {
    display: block;
    padding-top: 2px;
    padding-bottom: 1px;
    height: 1rem; // prevent text from shifting when icon changes
    margin-bottom: 0.25rem;
  }

  .bar {
    content: "";
    display: block;
    width: 16px;
    height: 2px;
    border-radius: 2px;
    margin: 0 auto;
    // @include transition;
    background-color: $swab-dark-blue;

    & + .bar {
      margin-top: 3px;
    }
  }

  &:hover {
    cursor: pointer;

    .bar {
      background-color: $swab-blue;
    }

    .bar.first {
      transform: rotate(45deg) translate(6.5px, 9.5px);
      width: 9px;
    }

    .bar.middle {
      transform: rotate(90deg) translate(0, -0.25px);
      width: 16px;
    }

    .bar.last {
      transform: rotate(135deg) translate(-1.5px, -3px);
      width: 9px;
    }
  }

  .show-nav & {
    background-color: $swab-pale-blue;
    color: $swab-dark-blue;

    .menu-text {
      color: $swab-dark-blue;
    }

    .bar {
      background-color: $swab-dark-blue;
      width: 18px;

      &.first {
        transform: rotate(45deg) translate(5px, 5px);
      }

      &.middle {
        display: none;
      }

      &.last {
        transform: rotate(-45deg) translate(-1px, 1.5px);
      }
    }

    &:hover {
      .bar {
        background-color: $swab-dark-blue;
      }

      .bar.first {
        transform: rotate(-45deg) translate(-2px, -1.5px);
        width: 9px;
      }

      .bar.middle {
        display: block;
        transform: rotate(90deg) translate(0, 0);
        width: 16px;
      }

      .bar.last {
        transform: rotate(45deg) translate(-5px, -9px);
        width: 9px;
      }
    }
  }
}

// mobile toggles (universal styles for search and menu)
.mobile-toggle {
  color: $swab-dark-blue;
  position: relative;
  font-size: 0.9rem;
  font-family: $header-font;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  text-align: center;
  background: none;
  padding: $vert-gutter*0.25;
  margin: $vert-gutter*0.25;
  width: 70px;
  height: 54px; // prevent from changing heights when icon changes
  @include transition;

  .menu-text {
    display: block;
    line-height: 1;
  }

  &:hover,
  &:focus {
    background-color: $swab-pale-blue;
    color: $swab-blue;
  }

  @include breakpoint($tablet-portrait-min) {
    display: block;
  }

  @include breakpoint($desktop-medium-min) {
    display: none;
  }
}

// mobile navigation wrapper
@include breakpoint($desktop-medium-max) {
  .header-regions {
    display: none;
    max-height: 95vh;
    max-height: calc(100% - 80px);
    height: calc(100% - 66px);
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    left: 0;
    right: 0;

    a {
      &:focus {
        outline-color: #fff;
      }
    }

    .header-top-region,
    .header-bottom-region {
      width: 100%;
    }

    .header-top-region {
      position: relative;
      z-index: 2;
    }

    .header-bottom-region {
      @include breakpoint($desktop-medium-max) {
        width: 100%;
        flex-grow: 2;
        position: relative;
        z-index: 1;
        display: block;
      }

      @include breakpoint($desktop-small-min) {
        display: flex;
        justify-content: flex-end;
        align-items: center;
      }

      @include breakpoint($desktop-medium-min) {
        .mainnavigation > ul {
          display: flex;
          justify-content: space-between;
        }
      }
    }

    [id="block-menu-menu-mini"] {
      > ul {
        a {
          color: #fff;
        }
      }
    }
  }
}

body {
  @include breakpoint($desktop-medium-max) {
    &.show-nav {
      height: 100%;
      overflow-y: hidden;

      .header-regions {
        background-color: $swab-dark-blue;
        color: #fff;
        top: 100px;
        display: block;
        z-index: 25;
        padding: $horiz-gutter;

        a {
          color: #fff;
          &.focus-visible {
            outline-color: #fff;
          }
        }
      }

      &.not-logged-in .header-regions {
        top: 72px;
      }
    }
  }
}

// In between header layout
@include breakpoint($desktop-medium-min $menu-stacked-max) {
  .header {
    .logo--main {
      position: absolute;
      top: 10px;
      min-width: 175px;
      max-width: auto;
    }

    .header-content {
      width: 100%;
    }

    .header-bottom-region {
      display: block;

      .mainnavigation > ul {
        display: flex;
        justify-content: space-between;
      }
    }
  }
}

.expanded {
  // position: relative;
  // width: 100%;

  .toggle-submenu {
    appearance: none;
    border: none;
    background-color: transparent;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;

    span {
      @include visually-hidden;
    }

    i {
      color: $swab-dark-red;
      font-size: 1.25rem;
    }
  }
}
