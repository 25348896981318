/* medium blue */
/* dark blue */
/* deep blue */
/* new light blue */
/* yellow gold */
/* Reading Rockets yellow */
/* red */
/* dark red */
.field-name-body ul li {
  list-style-type: none;
  position: relative;
}

.field-name-body ul li::before {
  content: '';
  display: inline-block;
  width: 5px;
  height: 5px;
  margin-right: 8px;
  margin-left: -13px;
  border-radius: 50%;
  background-color: #ed1c24;
  vertical-align: middle;
}

.field-name-body ul.links li::before {
  background-color: black;
}

.view-related-topics.view-display-id-block {
  border-top: 2px solid #ccc;
  margin: 10px 0;
  padding-bottom: 20px;
  padding-left: 25px;
  padding-top: 12px;
}

.view-related-topics.view-display-id-block h2 {
  color: #0071bc;
}

.view-related-topics.view-display-id-block .item-list {
  background-color: #eff9ff;
  border: 0.5px solid #ccc;
  padding: 20px 0 20px 25px;
}

.view-related-topics.view-display-id-block ul li {
  padding-bottom: 6px;
}

.view-related-topics.view-display-id-block ul li a {
  color: #ed1c24;
  display: block;
}

.book_blurb {
  padding: 18px;
  background: white;
  color: #191919;
  border-radius: 0 0 4px 4px;
}

.book_blurb * {
  font-size: 0.875rem;
}

.book_blurb h2 {
  margin: 8px 0 4px;
  padding-bottom: 10px;
  text-decoration: none;
  font-size: 1.1rem;
  font-weight: 700;
  color: #0071bc !important;
}

.book_blurb .author span,
.book_blurb .author div {
  display: inline;
}

.book_blurb .field {
  margin-bottom: 0;
}

.book_blurb .field-label {
  font-weight: normal;
}

.book_blurb .field > * {
  display: inline;
  margin-right: 5px;
}

.book-cover-description {
  border-bottom: 3px solid #000;
  position: relative;
  border-radius: 4px 4px 0 0;
  min-height: 160px;
  width: 100%;
  height: auto;
}

.field-book-image {
  margin: 0;
}

.book-description-overlay {
  background: rgba(239, 249, 255, 0.95);
  padding: 18px;
  font-size: 0.9rem;
  overflow-y: auto;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  color: #191919;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  border-radius: 4px 4px 0 0;
  max-height: 50em;
  transition: all 0.25s ease;
}

.book-description-overlay[aria-hidden="true"] {
  overflow: hidden;
  max-height: 0;
  top: 100%;
  padding: 0;
}

.book-description-overlay * {
  font-size: 0.9rem;
}

.book-description-overlay .purchase-links {
  margin: 20px 0 18px;
}

.book-description-overlay .purchase-links p {
  margin: 0;
}

.book-description-overlay .purchase-links p + p {
  margin-top: 8px;
}

.book-description-overlay a {
  transition: all 0.25s ease;
  margin-top: 9px;
  margin-bottom: 4.5px;
  appearance: none;
  border: none;
  width: auto;
  text-align: left;
  margin-left: 0;
  border-radius: 0;
  padding: 0;
  font-size: 0.9rem;
  background-color: transparent;
  position: relative;
  font-weight: 600;
}

.book-description-overlay a:hover, .book-description-overlay a:focus {
  text-decoration: none;
  color: #a40610;
}

.book-description-overlay a i {
  margin-left: 5px;
}

.book-info-button {
  position: absolute;
  bottom: -18px;
  left: 0;
  right: 0;
  margin: auto;
  appearance: none;
  display: inline-block;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.875rem;
  padding: 0;
  border: 1px solid #0071bc;
  background: #0071bc;
  color: #fff;
  border-radius: 18px;
  width: 32px;
  max-width: 120px;
  z-index: 3;
  white-space: nowrap;
  overflow: hidden;
  transition: none;
  cursor: pointer;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .book-info-button {
    transition: all 0.25s ease;
  }
}

.book-info-button span {
  display: inline-block;
  line-height: 0;
  text-align: left;
  color: #fff;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .book-info-button span {
    transition: all 0.5s ease;
  }
}

.book-info-button i {
  font-size: 32px;
  line-height: 1;
  display: inline-block;
  vertical-align: middle;
  margin-top: -1px;
  margin-right: -1px;
  margin-bottom: 2px;
  margin-left: -1px;
  transform: scale(1);
  position: relative;
  bottom: -2px;
}

@media screen and (prefers-reduced-motion: no-preference) {
  .book-info-button i {
    transition: all 0.25s ease;
  }
}

.book-info-button:hover {
  max-width: 118px;
  width: 100%;
  padding: 0 8px 0 2px;
  border-color: #191919;
}

.book-info-button:hover i {
  transform: scale(0.5);
  margin-right: -6px;
  bottom: -1px;
}

.book-info-button:hover span {
  line-height: 1;
  max-width: 200px;
  vertical-align: middle;
  padding-left: 2px;
}

.book-info-button:focus {
  border-color: #191919;
  padding: 0;
  border-radius: 18px;
  text-align: center;
}

.book-info-button:focus i {
  transform: scale(0.5);
  display: block;
  text-align: center;
  bottom: -1px;
}

.book-info-button:focus span {
  max-width: 0;
  overflow: hidden;
  text-indent: -99px;
  position: absolute;
}

.book-info-button:focus:hover {
  max-width: 118px;
  width: 100%;
  padding: 0 8px 0 0;
  border-color: #191919;
  display: inline-block;
}

.book-info-button:focus:hover i {
  display: inline-block;
}

.book-info-button:focus:hover span {
  max-width: 200px;
  vertical-align: middle;
  padding-left: 4px;
  opacity: 1;
  text-indent: 0;
  position: relative;
}

/* Global changes */
#page {
  overflow: hidden;
}

/* New Navigation */
[id="nav-wrapper"] {
  box-shadow: none !important;
}

[id="nav-wrapper"] * {
  box-sizing: border-box;
}

[id="nav-wrapper"] nav {
  margin: 0px !important;
}

[id="nav-wrapper"] .clearfix::before, [id="nav-wrapper"] .clearfix::after {
  content: "";
  display: none;
}

[id="nav-wrapper"] .clearfix:after {
  clear: both;
}

[id="nav-wrapper"] [id="mega-menu"] {
  display: flex;
  flex-wrap: wrap;
}

@media (max-width: 59.9375em) {
  [id="nav-wrapper"] [id="mega-menu"] {
    padding: 0 10px;
  }
  [id="nav-wrapper"] [id="mega-menu"] li {
    width: 100%;
  }
  [id="nav-wrapper"] [id="mega-menu"] li.is-active i {
    transform: rotate(0deg);
  }
}

@media (min-width: 60em) {
  [id="nav-wrapper"] [id="mega-menu"] > li + li {
    margin-left: 15px;
  }
}

@media (min-width: 81.25em) {
  [id="nav-wrapper"] [id="mega-menu"] > li + li {
    margin-left: 20px;
  }
}

@media (max-width: 59.9375em) {
  [id="nav-wrapper"] [id="mega-menu"] > li {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }
}

[id="nav-wrapper"] [id="mega-menu"] > li > a {
  font-size: 18px;
  color: #fff;
  position: relative;
  height: 3em;
  line-height: 3em;
  font-family: multi-display, sans-serif;
  font-weight: bold;
  display: flex;
  text-decoration: none;
}

@media (min-width: 60em) {
  [id="nav-wrapper"] [id="mega-menu"] > li > a {
    color: #191919;
  }
}

[id="nav-wrapper"] [id="mega-menu"] > li > a i {
  width: 18px;
  height: 18px;
  position: relative;
  text-align: right;
  color: #fff;
}

@media (min-width: 60em) {
  [id="nav-wrapper"] [id="mega-menu"] > li > a i {
    color: #a40610;
  }
}

@media (max-width: 59.9375em) {
  [id="nav-wrapper"] [id="mega-menu"] > li > a {
    justify-content: space-between;
    align-items: center;
    font-size: 1.2rem;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li > a i {
    transform: rotate(270deg);
  }
}

@media (min-width: 60em) {
  [id="nav-wrapper"] [id="mega-menu"] > li > a {
    position: relative;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li > a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 2px;
    background: #298aca;
    transition: all 0.25s ease;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li > a:hover::after, [id="nav-wrapper"] [id="mega-menu"] > li > a:focus::after {
    width: 100%;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li > a i {
    margin: auto;
  }
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown {
  display: none;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown div {
  display: block !important;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .layout-container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__links {
  float: left;
  width: 100%;
  overflow: hidden;
  *zoom: 1;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__links li {
  margin: 0;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__links li a {
  font-weight: bold;
  font-size: 1rem;
  display: block;
  position: relative;
  text-decoration: none;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__image {
  width: 25%;
  float: left;
  padding-right: 18px;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__image img {
  border-radius: 2px;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__text {
  color: #fff;
  width: 37.5%;
  float: left;
  font-size: 1rem;
  line-height: 1.5;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__text h2 {
  color: #fff;
  font-weight: bold;
  font-family: multi-display, sans-serif;
  padding-bottom: 10px;
  margin-top: 0;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__text .button {
  transition: all 0.25s ease;
  font-size: 1rem;
  font-family: multi-display, sans-serif;
  font-weight: bold;
  background-color: #fab74b;
  color: #191919 !important;
  width: auto;
  text-decoration: none;
  display: inline-block;
  padding: 10px 20px;
  border: 2px solid #fab74b;
}

[id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__text .button:hover, [id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__text .button:focus {
  background-color: #fff;
  text-decoration: underline;
  border: 2px solid #191919;
}

@media (max-width: 59.9375em) {
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__text,
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__image {
    display: none !important;
  }
}

@media (min-width: 60em) {
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown {
    position: absolute;
    z-index: 30;
    width: 100vw;
    right: -15px;
    background-color: #0071bc;
    padding: 60px 0;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown .desktop-small {
    display: none;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown .megamenu__links a {
    font-size: 0.9rem;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown.no-image .megamenu__links {
    width: 70%;
    padding-left: 18px;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown.no-image .megamenu__links ul {
    columns: 3;
    column-gap: 18px;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown.no-image .megamenu__text {
    width: 30%;
  }
  [id="nav-wrapper"] [id="mega-menu"] > li .dropdown.has-image .megamenu__links {
    width: 37.5%;
    padding-left: 18px;
  }
}

@media (min-width: 60em) {
  [id="nav-wrapper"] [id="mega-menu"] {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  [id="nav-wrapper"] [id="mega-menu"] li {
    padding: 0;
  }
  [id="nav-wrapper"] [id="mega-menu"] li > a {
    font-size: 92%;
  }
}

@media (min-width: 70.3125em) {
  [id="nav-wrapper"] [id="mega-menu"] {
    justify-content: flex-end;
  }
}

@media (min-width: 76.25em) {
  [id="nav-wrapper"] [id="mega-menu"] li > a {
    font-size: 17px;
  }
}

@media (min-width: 81.25em) {
  [id="nav-wrapper"] [id="mega-menu"] li > a {
    font-size: 18px;
  }
}

#nav-wrapper .menu li:hover > a {
  text-decoration: none;
}

#nav-wrapper .menu li.expanded ul {
  display: none;
  position: absolute;
  top: 3.1875em;
  padding: 15px 25px 25px;
  z-index: 99;
  background-color: #298aca;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#nav-wrapper .menu li.expanded.megamenu ul {
  left: 0;
  -webkit-column-count: 3;
  /* Chrome, Safari, Opera */
  -moz-column-count: 3;
  /* Firefox */
  -moz-columns: 3;
  /* Firefox */
  column-count: 3;
  -moz-column-fill: balance;
  -webkit-column-gap: 25px;
  /* Chrome, Safari, Opera */
  -moz-column-gap: 25px;
  /* Firefox */
  column-gap: 25px;
  max-height: 375px;
  transform: none;
}

#nav-wrapper .menu li.expanded.megamenu ul li {
  display: inline-block;
}

#nav-wrapper .menu li.expanded > a:before {
  content: "";
  background-image: url("../images/angle-down.svg");
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  align-self: center;
}

#nav-wrapper .menu li:hover ul,
#nav-wrapper .menu li ul li:hover,
#nav-wrapper .menu li:hover ul li:hover {
  display: block;
}

#nav-wrapper .menu li ul li {
  display: block;
  width: 100%;
}

#nav-wrapper .menu li ul li a {
  color: #fff;
  display: block;
  width: 100%;
  padding: 9px 0;
  font-weight: bold;
}

@media (min-width: 60em) {
  #nav-wrapper .menu li ul li a {
    border-bottom: 1px solid #fff;
  }
}

#nav-wrapper .menu li ul li a:hover {
  text-decoration: underline;
}

#mobile-menu {
  display: none;
}

.three-stars {
  background: url(../images/3stars_white.png) no-repeat 0 center;
  padding-left: 60px !important;
  background-size: 57px;
}

.nolink > a {
  pointer-events: none;
  cursor: default;
}

/* Social Link Styles */
.social-links {
  text-align: center;
}

.social-links a.imgmenu {
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  margin: 20px 0 0 10px;
  display: inline-block;
  text-align: center;
}

.social-links a#fb {
  background: transparent url(../images/fb_res.png) no-repeat center center;
  background-size: contain;
}

.social-links a#twitter {
  background: transparent url(../images/twitter_res.png) no-repeat center center;
  background-size: contain;
}

.social-links a#youtube {
  background: transparent url(../images/youtube_res.png) no-repeat center center;
  background-size: contain;
}

.social-links a#rss {
  background: transparent url(../images/rss_res.png) no-repeat center center;
  background-size: contain;
}

.social-links a#pinterest {
  background: transparent url(../images/pinterest.png) no-repeat center center;
  background-size: contain;
}

/* Button styles */
.cta-button {
  clear: both;
  padding-top: 30px;
}

.button {
  display: block;
  width: 33.3333%;
  margin: 0 auto;
  text-align: center;
  color: white !important;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  background-color: #29abe2;
  font-family: multi-display, sans-serif;
  padding: 8px 10px;
  box-sizing: border-box;
  transition: all 0.25s ease;
}

.button:hover {
  text-decoration: none;
  cursor: pointer;
}

.red-button {
  background-color: #ed1c24;
  /* red */
}

.red-button:hover {
  background-color: #A40610;
  /* dark red */
}

/* Home Image Panel Styles */
.image-strip {
  width: 100vw !important;
  max-width: 100vw !important;
  background: url(../images/swab-cloudsbkgrd.png) no-repeat 0 center;
  background-size: cover;
  margin-left: calc(-100vw / 2 + 940px / 2) !important;
  margin-right: calc(-100vw / 2 + 940px / 2) !important;
}

.image-strip img {
  display: block;
  margin: 0 auto;
  max-width: 960px;
}

.bottom-strip {
  color: red;
  padding: 20px 20px 0px;
  text-align: center;
  font-size: 2em;
  letter-spacing: 0.25px;
  font-family: "open-sans", Arial, Helvetica, sans-serif;
}

.bottom-strip p {
  margin-bottom: 5px;
}

/* New Home Styles */
.home-panels {
  display: none !important;
}

.home-row {
  padding: 30px 0px !important;
  border-bottom: 1px dotted black;
}

.home-row:last-child {
  border-bottom: 0px;
}

.home-row.row-first {
  padding-top: 0px !important;
}

@media (max-width: 29.9375em) {
  .home-row .panels-flexible-region .panel-pane:first-child {
    margin-bottom: 10px;
  }
}

.one-third,
.pane-bundle-featured-content,
.pane-bundle-featured-video {
  width: 33.3333%;
  float: left;
  box-sizing: border-box;
  padding: 0px 15px;
}

.negative-margin {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

img[width="625"] {
  width: 100% !important;
}

/* Intro Blocks */
.intro-blocks {
  text-align: center;
}

.intro-blocks img {
  max-width: 50%;
  text-align: center;
  margin: 0 auto;
  box-sizing: border-box;
}

@media (max-width: 29.9375em) {
  .intro-blocks img {
    max-width: 115px;
  }
}

.intro-blocks h2,
.intro-blocks p {
  text-align: left;
}

.intro-blocks h2 {
  padding-bottom: 10px;
  margin-top: 0;
}

@media (min-width: 30em) {
  .intro-blocks h2 {
    margin-top: 15px;
  }
}

@media (max-width: 29.9375em) {
  .panels-flexible-region-4-intro_blocks .one-third {
    padding: 0 !important;
  }
}

/* Featured Content and Featured Video */
.pane-bundle-featured-content,
.pane-bundle-featured-video {
  display: flex;
  flex-wrap: wrap;
}

.pane-bundle-featured-video .field-name-field-featured-video-image {
  margin-bottom: 10px;
}

.field-name-field-featured-kicker {
  color: #191919;
  font-family: "open-sans", Arial, Helvetica, sans-serif;
  font-weight: bold;
}

.reading-tips-image {
  position: relative;
  top: -0.76vw;
}

.pane-bundle-featured-content img {
  width: 100%;
  height: auto;
}

.pane-bundle-featured-content .pane-title,
.pane-bundle-featured-video .pane-title,
.view-related-blogs .pane-title {
  display: block !important;
  order: 2;
  color: #0071bc;
  margin-top: 0;
}

.pane-bundle-featured-content .pane-content,
.pane-bundle-featured-video .pane-content {
  order: 1;
  width: 100%;
}

.pane-bundle-featured-content .pane-title a,
.pane-bundle-featured-content .pane-title a:visited,
.pane-bundle-featured-video .pane-title a,
.pane-bundle-featured-video .pane-title a:visited,
.view-related-blogs .pane-title a,
.view-related-blogs .pane-title a:visited {
  color: #0071bc;
  text-decoration: none;
}

.pane-bundle-featured-content .pane-title a:hover,
.pane-bundle-featured-content .pane-title a:active
.pane-bundle-featured-video .pane-title a:hover,
.pane-bundle-featured-video .pane-title a:active,
.view-related-blogs .pane-title a:hover,
.view-related-blogs .pane-title a:active {
  color: #29abe2;
  /* new light blue */
  text-decoration: none;
  font-family: multi-display, sans-serif;
}

.pane-bundle-featured-video .player {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 55%;
  margin-bottom: 5px;
}

.pane-bundle-featured-video .player iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Video Popup */
.pane-bundle-featured-video .pane-title {
  cursor: pointer;
}

.field-name-field-featured-video-image img {
  transition: opacity 0.3s ease 0.25s ease;
  cursor: pointer;
  opacity: 0.95;
}

.field-name-field-featured-video-image img:hover {
  opacity: 1;
}

.field-name-field-featured-video {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  z-index: 500;
}

.field-name-field-featured-video .field-item {
  max-width: 960px;
  margin: 0 auto;
  padding-top: 48px;
}

.field-name-field-featured-video iframe {
  border: 1px solid #fff;
  box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 1px 4px 4px rgba(0, 0, 0, 0.5);
  box-sizing: border-box;
}

/* Testimonial */
.view-testimonials {
  text-align: center;
  color: #29abe2;
  font-family: multi-display, sans-serif;
  font-weight: 400;
}

.view-testimonials .views-field-body {
  font-size: 1.5em;
}

.view-testimonials .views-field-field-testimonial-author {
  margin-top: 20px;
  color: #0071bc;
  font-size: 1.2em;
  font-family: "open-sans", Arial, Helvetica, sans-serif;
}

/* Footer */
#footer-wrapper,
#footer-bottom {
  font-size: 0.9rem;
}

#footer-wrapper h2, #footer-wrapper p,
#footer-bottom h2,
#footer-bottom p {
  color: #fff;
}

#footer-wrapper .container,
#footer-bottom .container {
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 50px;
  padding-bottom: 20px;
}

@media (max-width: 59.9375em) {
  #footer-wrapper .container,
  #footer-bottom .container {
    padding-left: 0;
    padding-right: 0;
  }
  #footer-wrapper .region-footer,
  #footer-bottom .region-footer {
    padding: 0 20px;
  }
}

#footer-bottom .container {
  padding-top: 30px;
  padding-bottom: 10px;
}

#footer-wrapper .region-footer #block-block-38,
#footer-wrapper .region-footer #block-block-48,
#footer-wrapper .region-footer #block-block-47 {
  width: 100%;
}

#footer-wrapper .region-footer h2 {
  font-size: 1.2rem;
  margin-top: 0;
}

#footer-wrapper .region-footer #block-block-40 {
  display: none;
}

#footer-wrapper .region-footer #block-block-47 p {
  margin-bottom: 6px;
}

@media (min-width: 37.5em) {
  #footer-wrapper .region-footer #block-block-47,
  #footer-wrapper .region-footer #block-block-48 {
    width: 46%;
  }
  #footer-wrapper .region-footer #block-block-47 {
    padding-right: 20px;
  }
  #footer-wrapper .region-footer #block-block-48 {
    padding-left: 20px;
  }
}

@media (min-width: 60em) {
  #footer-wrapper .region-footer #block-block-38,
  #footer-wrapper .region-footer #block-block-48,
  #footer-wrapper .region-footer #block-block-47 {
    width: 33%;
  }
  #footer-wrapper .region-footer #block-block-38 {
    padding-right: 20px;
  }
  #footer-wrapper .region-footer #block-block-48 {
    padding: 0 20px;
  }
  #footer-wrapper .region-footer #block-block-47 {
    padding-left: 20px;
    width: 20%;
  }
}

@media (min-width: 76.25em) {
  #footer-wrapper .region-footer #block-block-47 {
    width: 33%;
  }
}

#footer-wrapper img {
  margin-bottom: 20px;
  max-width: 150px;
}

#footer-wrapper .logo-footer img {
  max-width: 200px;
}

#footer-wrapper #block-block-47 img {
  max-width: 100px;
  margin-bottom: 20px;
}

#footer-wrapper a:not(.logo-footer) img {
  margin-top: 20px;
  margin-bottom: 40px;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper {
  position: relative;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"],
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"],
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"],
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"] {
  border: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: none;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"] + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"] + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"] + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"] + label {
  color: #fff;
  cursor: pointer;
  font-weight: normal;
  width: 100%;
  transition: all 0.25s ease;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"] + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"] + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"] + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"] + label::after {
  border-color: #191919;
  background-color: #fff;
  content: "";
  display: block;
  position: absolute;
  vertical-align: middle;
  border-width: 2px;
  border-style: solid;
  border-radius: 4px;
  font-weight: normal;
  text-align: center;
  transition: all 0.25s ease;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"]:checked + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"]:checked + label::after {
  content: "\f00c";
  font-family: "Font Awesome 6 Pro";
  font-weight: bold;
  color: #191919;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"] + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"] + label::after {
  border-radius: 50%;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"]:checked + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"]:checked + label::after {
  content: "\2022";
  /* Tick */
  font-size: 2.5em;
  line-height: 20px;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper label {
  font-size: 16px;
  line-height: 26px;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"] + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"] + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"] + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"] + label {
  padding: 0 18px 0 32px;
  border-color: transparent;
  background-color: transparent;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"] + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"] + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"] + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"] + label::after {
  left: 0;
  top: 0;
  width: 24px;
  height: 24px;
  line-height: 24px;
  background-color: transparent;
  font-size: 0.9em;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"]:hover + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"]:hover + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"]:hover + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"]:hover + label::after {
  background-color: #eff9ff;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"]:focus + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"]:focus + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"]:focus + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"]:focus + label {
  outline-width: 2px;
  outline-style: dotted;
  outline-offset: 2px;
  outline-color: #fab74b;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"]:focus + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"]:focus + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"]:focus + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"]:focus + label::after {
  background-color: #eff9ff;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"]:checked + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"]:checked + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"]:checked + label,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"]:checked + label {
  font-weight: bold;
  color: #fff;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"]:checked + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"]:checked + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"]:checked + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"]:checked + label::after {
  background-color: #fff;
  color: #298aca;
  border-color: #0071bc;
}

#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="checkbox"]:checked:hover + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-checkbox .form-item-wrapper input[type="radio"]:checked:hover + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="checkbox"]:checked:hover + label::after,
#footer-wrapper .mailchimp-subscribe .js-form-type-radio .form-item-wrapper input[type="radio"]:checked:hover + label::after {
  color: #191919;
  border-color: #191919;
  background-color: #eff9ff;
}

#footer-wrapper .mailchimp-subscribe .visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

#footer-wrapper .mailchimp-subscribe ::placeholder {
  color: #191919;
}

#footer-wrapper .mailchimp-subscribe input[type=email] {
  font-family: "open-sans", Arial, Helvetica, sans-serif;
  font-size: 1rem;
  border: 2px solid #191919;
  border-radius: 4px;
  background-color: #fff;
  color: #191919;
  height: 38px;
  width: 95%;
  line-height: 1;
  padding: 4px 10px;
  margin-bottom: 10px;
}

#footer-wrapper .mailchimp-subscribe .button--secondary {
  background-color: #ed1c24;
  color: white;
  font-weight: normal;
  border: 1px solid #c61017;
  border-radius: 2px;
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: 0 1px 1px #970c11;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 8px 16px;
  transition: all 0.25s ease;
  appearance: none;
  text-transform: capitalize;
  font-weight: bold;
  border-radius: 4px !important;
  border: 2px solid #fff !important;
  line-height: inherit;
  text-shadow: none;
  font-family: multi-display, sans-serif;
  text-decoration: none;
  margin-top: 10px;
}

#footer-wrapper .mailchimp-subscribe .button--secondary:hover {
  text-decoration: none;
  color: white;
  background-color: #c61017;
  background-image: -owg(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: -webkit(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: -moz(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: -o(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false);
}

#footer-wrapper .mailchimp-subscribe .button--secondary:active, #footer-wrapper .mailchimp-subscribe .button--secondary:focus {
  text-decoration: none;
  color: white;
  background-color: #68080c;
}

#footer-wrapper .mailchimp-subscribe .button--secondary:visited {
  color: white;
}

#footer-wrapper .mailchimp-subscribe .button--secondary:hover, #footer-wrapper .mailchimp-subscribe .button--secondary:focus {
  color: #191919 !important;
  cursor: pointer;
  box-shadow: none;
  text-shadow: none;
  background-color: #fff !important;
  border-color: #191919 !important;
  text-decoration: underline;
}

#footer-bottom {
  background-color: #191919;
}

#footer-bottom #block-block-11 p + p {
  margin-bottom: 0;
}

@media (max-width: 47.9375em) {
  #footer-bottom #block-block-11 p {
    text-align: center;
    margin-top: 10px;
  }
}

#footer-bottom .container {
  padding-left: 0;
  padding-right: 0;
}

#footer-bottom .container .block-inner {
  margin: 0;
}

#footer-bottom .region-footer-bottom {
  padding: 0 25px;
}

@media (min-width: 48em) {
  #footer-bottom .region-footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

@media (min-width: 76.25em) {
  #footer-bottom .region-footer-bottom {
    padding: 0 10px;
  }
}

#footer-bottom a {
  color: #fff;
}

.region-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (min-width: 60em) {
  .region-footer {
    flex-wrap: nowrap;
  }
}

.region-footer a {
  text-decoration: underline;
}

.region-footer a:hover, .region-footer a:focus {
  text-decoration: none;
}

.external-link-icon {
  width: 0.825em;
  margin-left: 0.25em;
}

.external-link-icon .visually-hidden {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.social-links a {
  background-color: #0071bc;
  color: #fff;
  display: block;
  text-indent: -9999px;
  text-align: right;
  padding: 0 !important;
  overflow: hidden;
  width: 44px;
  height: 44px;
  position: relative;
  display: inline-block;
  margin: 0;
}

.social-links a + a {
  margin-left: 5px;
}

.social-links .facebook {
  background: transparent url(../images/fb_res.png) no-repeat center center;
  background-size: contain;
}

.social-links .pinterest {
  background: transparent url(../images/pinterest.png) no-repeat center center;
  background-size: contain;
}

.social-links .twitter {
  background: transparent url(../images/twitter_res.png) no-repeat center center;
  background-size: contain;
}

.social-links .youtube {
  background: transparent url(../images/youtube_res.png) no-repeat center center;
  background-size: contain;
}

@media (max-width: 47.9375em) {
  .social-links {
    text-align: center;
    margin-top: 10px;
  }
}

/* DC For Kids */
.view-dc-for-kids .views-field-field-url {
  display: inline-block;
}

.view-dc-for-kids .views-field-field-url a {
  color: #0071bc;
}

.view-dc-for-kids .views-field-field-url a:hover {
  color: #2e3192;
}

.view-dc-for-kids .views-field-field-subscrip {
  display: inline-block;
}

.in-this-section {
  clear: both;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.left {
  overflow: hidden;
}

.credit {
  display: block;
  position: relative;
  top: -5px;
}

/* Topics Pages */
.contentbox {
  padding-top: 15px;
  margin-top: 40px;
}

.contentbox h2 {
  margin-top: 0;
}

.contentbox .row .box {
  width: calc(25% - 1.5em);
}

.contentbox .row .box:last-child {
  margin-right: 0px;
}

.contentbox .row .box img {
  width: 100%;
  height: auto;
}

.contentbox .row .box a {
  display: block;
}

.contentbox .row .box span {
  background: none !important;
  color: #ed1c24 !important;
}

.bookbox {
  overflow: hidden;
}

.bookbox div {
  max-width: 100%;
}

.topics-kicker {
  margin-bottom: 3px !important;
  margin-top: 12px !important;
}

.booklist-left {
  padding-bottom: 20px;
}

.booklist-left div.intro {
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  padding-top: 15px;
  margin-bottom: 20px;
}

.booklist-section .left {
  float: left;
  width: 40%;
}

.booklist-section .left img {
  width: calc(100% - 1em);
}

.booklist-right {
  margin-top: 5px;
}

/* Sidebar */
.region-sidebar-first h2.block-title {
  text-align: center;
}

/* show more button */
.reveal-button:last-letter {
  color: #ed1c24;
}

/* DIY Science Pages */
.article-science-topic .field-name-field-science-image {
  float: left;
  width: 40% !important;
}

.article-science-topic .field-name-field-science-image img {
  max-width: 100%;
}

.article-science-topic .field-name-field-science-intro .imgleft {
  float: right;
  width: calc(60% - 30px);
}

.article-science-topic .field-name-field-science-intro .imgleft .left {
  float: left;
}

.article-science-topic .jump-links {
  float: right;
  width: calc(60% - 60px);
}

.view-related-videos .views-field-field-image .field-content {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 58.25%;
  margin-bottom: 10px;
  max-width: calc(100% + 30px) !important;
}

.view-related-videos .views-field-field-image iframe {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.bookbox img {
  width: 100%;
  max-width: 100%;
}

/* Responsive Styles */
/* Tablet landscape */
@media only screen and (min-width: 769px) and (max-width: 1024px) {
  .container {
    max-width: 100% !important;
  }
  .negative-margin {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .image-strip {
    max-width: calc(100vw) !important;
  }
  .image-strip img {
    width: 100vw;
  }
}

.intro-text {
  text-align: center;
}

.intro-text h1 {
  margin-top: 0;
}

@media (min-width: 48em) {
  .intro-text h1 {
    font-size: 3.75rem;
  }
}

.intro-text p {
  font-size: 1.4rem;
  color: #ed1c24;
}

/* Special image strip breakpoint */
@media only screen and (max-width: 959px) {
  .image-strip {
    background: none;
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: auto !important;
    max-width: 100% !important;
  }
  .image-strip img {
    width: 100%;
  }
}

/* Tablet portrait */
@media only screen and (min-width: 481px) and (max-width: 768px) {
  #header-wrapper .ricky-rocket {
    width: 25%;
  }
  #header-wrapper .header-text {
    width: 75%;
  }
  #header-wrapper .header-text p {
    font-size: 1em;
  }
  .view-home-panel-images {
    margin-left: -15px;
    margin-right: -15px;
  }
  .container {
    max-width: 100% !important;
  }
  #nav-wrapper .menu li.expanded ul {
    display: none !important;
  }
  #nav-wrapper .menu li.expanded > a:after {
    display: none;
  }
  .one-third,
  .pane-bundle-featured-content,
  .pane-bundle-featured-video {
    width: 100%;
    padding: 10px 0;
  }
  .home-row.row-first {
    padding-top: 0px !important;
  }
  .intro-blocks img {
    max-width: 25%;
    float: left;
    margin: 0px;
    padding-right: 25px;
  }
  .intro-blocks h2,
  .intro-blocks p {
    max-width: 75%;
    float: left;
    margin: 0px;
    clear: none;
  }
  .button {
    width: 75%;
  }
  .home-row {
    padding: 50px 30px;
  }
  .negative-margin {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .bottom-strip {
    font-size: 1.5em;
  }
  .contentbox .row .box {
    width: calc(50% - 1em);
  }
  .contentbox .row .box:nth-child(even) {
    margin-right: 0px;
  }
  .contentbox .row .box:nth-child(odd) {
    clear: both;
  }
  .booklist-section .left,
  .booklist-section .fRight {
    width: 100% !important;
  }
  /* DIY Science Pages */
  .article-science-topic .field-name-field-science-image {
    width: 100% !important;
    clear: both;
  }
  .article-science-topic .field-name-field-science-intro .imgleft {
    float: left;
    width: 100%;
  }
  .article-science-topic .jump-links {
    float: left;
    width: 100%;
  }
  .view-related-videos .views-field-field-image .field-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* Smartphone landscape */
@media only screen and (max-width: 480px) {
  /* Mobile Menu */
  #mobile-menu {
    display: block;
    background-color: #0071bc;
  }
  #nav-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
  }
  #nav-wrapper .menu li {
    text-align: left;
  }
  #nav-wrapper .three-stars {
    background: none;
    padding-left: 0px !important;
  }
  .show-nav #nav-wrapper {
    max-height: 99em;
  }
  #nav-wrapper .menu li.expanded ul {
    display: none !important;
  }
  #nav-wrapper .menu li.nolink {
    border-top: 0px;
  }
  #nav-wrapper .menu li.nolink > a {
    display: none;
    height: 0;
  }
  #nav-wrapper .menu li.nolink ul {
    display: block !important;
    position: relative;
    top: 0;
    padding: 0;
    background-color: #0071bc;
    border-top: 1px solid #29abe2;
  }
  #nav-wrapper .menu li.nolink ul #nav-wrapper .menu li.expanded ul
li {
    width: 100%;
  }
  #nav-wrapper .menu li.nolink ul #nav-wrapper .menu li.expanded ul
li a {
    height: 3em !important;
    line-height: 3em !important;
    color: white;
  }
  #nav-wrapper .menu li ul li a {
    color: white !important;
  }
  #nav-wrapper .menu li.expanded > a:after {
    display: none;
  }
  .ricky-rocket {
    top: 0;
  }
  #header-wrapper {
    position: relative;
  }
  #header-wrapper .header-text p {
    font-size: 0.75em;
    margin-top: 0px;
    padding-left: 20px;
  }
  #block-block-23 {
    margin-right: 20px;
  }
  /* Search Styles */
  #block-block-23 {
    margin: 15px 25px 0px !important;
    width: 180px !important;
  }
  #cse-search-form {
    width: 180px;
    display: block;
    background: white;
  }
  #search-toggle {
    display: none;
  }
  .show-search #search-toggle {
    display: none;
  }
  .container {
    max-width: 100% !important;
  }
  .one-third,
  .pane-bundle-featured-content,
  .pane-bundle-featured-video {
    width: 100%;
    padding: 10px 0px !important;
  }
  .home-row.row-first {
    padding-top: 0px !important;
  }
  .negative-margin {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .button {
    width: 100%;
  }
  .bottom-strip {
    font-size: 1.5em;
  }
  .view-home-panel-images {
    margin-left: -15px;
    margin-right: -15px;
  }
  .contentbox .row .box {
    width: calc(50% - 1em);
    font-size: 1.25em;
  }
  .contentbox .row .box:nth-child(even) {
    margin-right: 0px;
  }
  .contentbox .row .box:nth-child(odd) {
    clear: both;
  }
  /* DIY Science Pages */
  .article-science-topic .field-name-field-science-image {
    width: 100% !important;
    clear: both;
  }
  .article-science-topic .field-name-field-science-intro .imgleft {
    float: left;
    width: 100%;
  }
  .article-science-topic .jump-links {
    float: left;
    width: 100%;
  }
  .view-related-videos .views-field-field-image .field-content {
    margin-left: 0px;
    margin-right: 0px;
  }
}

/* Smartphone portrait */
@media only screen and (max-width: 320px) {
  .container {
    max-width: 100% !important;
  }
  .one-third,
  .pane-bundle-featured-content,
  .pane-bundle-featured-video {
    width: 100%;
  }
  .button {
    width: 100%;
  }
}

.field-name-field-blog-tags {
  border-top: 1px solid #ccc;
  padding: 15px 0;
}

.field-name-field-blog-tags .field-label {
  font-weight: normal !important;
  padding-bottom: 8px !important;
}

.middle-col {
  float: left;
  width: 29%;
  margin-right: 3%;
}

.middle-col .view-related-videos {
  float: none !important;
  width: 100%;
}

.middle-col .view-related-blogs {
  padding-top: 20px;
  border-top: 1px solid #ccc;
}

.middle-col .view-related-blogs h3 {
  padding: 10px 0;
}

@media only screen and (max-width: 480px) {
  .middle-col {
    width: 100%;
    margin-right: 0;
  }
  .middle-col .view-related-videos {
    padding-top: 20px;
  }
}

.view-related-blogs .views-field.views-field-title {
  background: none !important;
  background-color: white !important;
  margin-bottom: 5px;
}

.field-name-field-featured-image img {
  width: 100%;
  margin-bottom: 10px;
}

.homepage-carousel {
  min-height: 220px;
  height: 100%;
  height: auto;
  position: relative;
}

.homepage-carousel .article-homepage-carousel-slide {
  margin: 0;
}

.homepage-carousel .slick-slide > div {
  height: 100%;
}

.homepage-carousel .slick-slide > div > div {
  height: 100%;
}

.homepage-carousel .slick-next,
.homepage-carousel .slick-prev {
  position: absolute;
  z-index: 15;
  width: 32px;
  border-radius: 50%;
  transition: all .25s ease;
  background: rgba(0, 0, 0, 0);
  height: 64px;
  opacity: .5;
  color: transparent;
  text-decoration: none;
  display: block;
  text-indent: -999px;
  overflow: hidden;
}

.homepage-carousel .slick-next::before, .homepage-carousel .slick-next::after,
.homepage-carousel .slick-prev::before,
.homepage-carousel .slick-prev::after {
  content: "";
  border-radius: 1px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  width: 3px;
  height: 16px;
  background: #fff;
}

.homepage-carousel .slick-next::before,
.homepage-carousel .slick-prev::before {
  transform: rotate(45deg);
  top: 10px;
}

.homepage-carousel .slick-next::after,
.homepage-carousel .slick-prev::after {
  transform: rotate(-45deg);
  top: -10px;
}

.homepage-carousel .slick-next:hover, .homepage-carousel .slick-next:focus,
.homepage-carousel .slick-prev:hover,
.homepage-carousel .slick-prev:focus {
  opacity: 1;
  background: rgba(0, 0, 0, 0.5);
  text-decoration: none;
}

.homepage-carousel .slick-next {
  border-radius: 32px 0 0 32px;
  right: 0;
}

@media (min-width: 60em) {
  .homepage-carousel .slick-next {
    right: 10px;
    border-radius: 50%;
    width: 64px;
  }
}

.homepage-carousel .slick-prev {
  border-radius: 0 32px 32px 0;
  left: 0;
}

.homepage-carousel .slick-prev::before {
  transform: rotate(-45deg);
}

.homepage-carousel .slick-prev::after {
  transform: rotate(45deg);
}

@media (min-width: 60em) {
  .homepage-carousel .slick-prev {
    left: 10px;
    border-radius: 50%;
    width: 64px;
  }
}

.homepage-carousel .slick-autoplay-toggle-button {
  position: absolute;
  bottom: -10px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 5;
  text-align: center;
  background: #fab74b;
  color: #191919;
  max-width: 30px;
  height: 30px;
  border: 2px solid #191919;
  border-radius: 18px;
  padding: 0;
  white-space: nowrap;
  overflow: hidden;
  transition: all 0.3s ease;
  font-size: 0.875rem;
  line-height: 28px;
  font-weight: 700;
  opacity: 1;
  text-transform: uppercase;
}

.homepage-carousel .slick-autoplay-toggle-button .slick-play-icon,
.homepage-carousel .slick-autoplay-toggle-button .slick-pause-icon {
  width: 28px;
  height: 28px;
  display: inline;
  padding-left: 7px;
  padding-right: 8px;
  line-height: 29px;
  text-align: center;
  font-size: 0.8rem;
  vertical-align: top;
}

.homepage-carousel .slick-autoplay-toggle-button .slick-play-icon::before {
  content: "\f04b";
  font-family: "Font Awesome 6 Pro";
  font-weight: bold;
  position: relative;
  color: #191919;
  left: 1px;
}

.homepage-carousel .slick-autoplay-toggle-button .slick-pause-icon::before {
  content: "\f04c";
  font-family: "Font Awesome 6 Pro";
  font-weight: bold;
  color: #191919;
}

.homepage-carousel .slick-autoplay-toggle-button .slick-sr-only {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.homepage-carousel .slick-autoplay-toggle-button .slick-sr-only.slick-pause-text, .homepage-carousel .slick-autoplay-toggle-button .slick-sr-only.slick-play-text {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
  clip-path: none !important;
  clip: unset !important;
  font-weight: bold;
  text-transform: uppercase;
  color: #191919;
  display: inline;
  line-height: 28px;
}

.homepage-carousel .slick-autoplay-toggle-button:hover, .homepage-carousel .slick-autoplay-toggle-button:focus {
  max-width: 100px;
  padding-right: 2.5px;
}

@media (max-width: 59.9375em) {
  .homepage-carousel .slick-autoplay-toggle-button {
    bottom: -15px;
  }
}

.homepage-carousel .article-homepage-carousel-slide .field-name-field-carousel-image img {
  max-height: 600px;
  object-fit: cover;
  width: 100%;
}

.homepage-carousel .article-homepage-carousel-slide .field-slide-text {
  display: block;
  line-height: 1.6;
}

.homepage-carousel .article-homepage-carousel-slide .field-slide-text > *:last-child {
  margin-bottom: 0;
}

.homepage-carousel .article-homepage-carousel-slide .field-carousel-text {
  font-weight: 400;
  text-align: center;
  line-height: 1;
  z-index: 2;
  color: #191919;
  font-family: "verveine", sans-serif;
  font-size: 2rem;
}

.homepage-carousel .article-homepage-carousel-slide .carousel-link {
  transition: all 0.25s ease;
  text-decoration: none;
  color: #191919;
}

.homepage-carousel .article-homepage-carousel-slide .carousel-link::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: block;
  width: 100%;
  height: 100%;
}

.homepage-carousel .article-homepage-carousel-slide .carousel-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.homepage-carousel .article-homepage-carousel-slide .carousel-text {
  display: block;
}

@media (min-width: 48em) {
  .homepage-carousel .article-homepage-carousel-slide .carousel-text {
    padding-left: 70px;
    padding-right: 70px;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
  .homepage-carousel .article-homepage-carousel-slide .field-carousel-text {
    font-size: 5.125rem;
    position: static;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 100%;
    z-index: 2;
  }
  .homepage-carousel .article-homepage-carousel-slide .blob {
    z-index: -1;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    display: block;
  }
  .homepage-carousel .article-homepage-carousel-slide .blob path {
    fill: #a4f3ed;
  }
  .homepage-carousel .article-homepage-carousel-slide.position--right .field-carousel-text {
    margin-left: auto;
    margin-right: 0;
  }
  .homepage-carousel .article-homepage-carousel-slide.position--right .blob {
    right: 70px;
    transform: translate(8%, 0);
  }
  .homepage-carousel .article-homepage-carousel-slide.position--left .blob {
    left: 70px;
  }
}

@media (min-width: 76.25em) {
  .homepage-carousel .article-homepage-carousel-slide .field-carousel-text {
    width: 360px;
  }
  .homepage-carousel .article-homepage-carousel-slide .blob {
    width: 400px;
    height: auto;
  }
}

@media (min-width: 48em) and (max-width: 76.1875em) {
  .homepage-carousel .article-homepage-carousel-slide .field-carousel-text {
    width: 220px;
    font-size: 3rem;
  }
  .homepage-carousel .article-homepage-carousel-slide .blob {
    width: 250px;
    height: auto;
  }
}

@media (max-width: 47.9375em) {
  .homepage-carousel .article-homepage-carousel-slide .blob {
    display: none;
  }
  .homepage-carousel .article-homepage-carousel-slide .field-carousel-text {
    padding: 10px 20px 20px;
    background-color: #a4f3ed;
  }
}

.front .region-highlighted .block-inner {
  margin: 0;
}

.block-swab-share-print .block-inner {
  margin: 0;
}

.block-swab-share-print ul {
  display: flex;
  flex-direction: row;
}

.block-swab-share-print ul li {
  margin: 0;
  padding: 0;
  width: 44px;
  height: 44px;
}

.block-swab-share-print ul a {
  transition: all 0.25s ease;
  width: 44px;
  height: 44px;
  margin: 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: 1.4rem;
  text-decoration: none;
}

.block-swab-share-print ul a i {
  color: #0071bc;
}

.block-swab-share-print ul a:hover, .block-swab-share-print ul a:focus {
  text-decoration: none;
}

.block-swab-share-print ul a:hover i, .block-swab-share-print ul a:focus i {
  color: #ed1c24;
}

@media (min-width: 76.25em) {
  .block-swab-share-print {
    width: 45px;
    position: fixed;
    top: 40%;
    left: 0;
    z-index: 15;
  }
  .block-swab-share-print ul {
    flex-direction: column;
  }
  .block-swab-share-print ul a {
    outline-color: rgba(0, 0, 0, 0);
    border: 2px solid;
    border-color: #191919;
    background: #fafafa;
  }
  .block-swab-share-print ul a:hover, .block-swab-share-print ul a:focus {
    background-color: #298aca;
  }
  .block-swab-share-print ul a:hover i, .block-swab-share-print ul a:focus i {
    color: #fff;
  }
}

#page .container {
  position: initial;
}

.region-secondary-content {
  margin-bottom: 50px;
  background-image: url("../images/swab-cloudsbkgrd.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.region-secondary-content .block {
  padding: 0;
  margin: 0;
}

.region-secondary-content img {
  width: 600px;
  display: block;
  margin: auto;
}

@media (min-width: 60em) {
  .region-secondary-content .block {
    margin-left: auto;
    margin-right: auto;
    max-width: 1200px;
    width: 100%;
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 0;
    padding: 0;
  }
  .region-secondary-content img {
    width: 800px;
  }
}

@media (min-width: 90em) {
  .region-secondary-content img {
    width: 1100px;
  }
}

img[style="float:left"] {
  padding-right: 25px;
  padding-bottom: 25px;
}

img[style="float:right"] {
  padding-left: 25px;
  padding-bottom: 25px;
}

[id="header-main"] {
  display: flex;
  justify-content: space-between;
  position: relative;
}

@media (min-width: 60em) and (max-width: 70.25em) {
  [id="header-main"] {
    justify-content: flex-end;
  }
}

[id="header-main"] .logo {
  width: 155px;
  height: auto;
  background-image: url("../images/logo-mobile.svg");
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
}

@media (min-width: 60em) {
  [id="header-main"] .logo {
    width: 215px;
    background-image: url("../images/logo.svg");
  }
}

@media (min-width: 76.25em) {
  [id="header-main"] .logo {
    margin-right: 14.4px;
    width: 200px;
  }
}

@media (min-width: 60em) and (max-width: 70.25em) {
  [id="header-main"] .logo {
    position: absolute;
    top: 10px;
    left: 0;
    width: 215px;
    height: 75px;
    z-index: 15;
  }
  .logged-in [id="header-main"] .logo {
    top: 35px;
  }
}

@media (min-width: 90em) {
  [id="header-main"] .logo {
    width: 240px;
  }
}

[id="header-main"] .header-content {
  display: flex;
}

.header {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 59.9375em) {
  .header {
    padding-right: 0;
  }
}

.header .region-leaderboard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 10px 10px 0;
}

@media (min-width: 60em) {
  .header .region-leaderboard {
    justify-content: flex-end;
    padding: 0;
  }
}

.header .region-leaderboard .block .block-inner {
  padding: 0;
  margin: 0;
}

.header .region-leaderboard #block-menu-menu-mini {
  width: auto;
}

.header .region-leaderboard #block-menu-menu-mini li {
  border: none;
}

.header .region-leaderboard #block-menu-menu-mini li a {
  text-decoration: none;
}

@media (max-width: 59.9375em) {
  .header .region-leaderboard #block-menu-menu-mini {
    order: 3;
    width: 100%;
  }
  .header .region-leaderboard #block-menu-menu-mini .menu {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .header .region-leaderboard #block-menu-menu-mini .menu::after {
    display: none;
  }
  .header .region-leaderboard #block-menu-menu-mini li {
    margin: 0 10px;
    padding: 0;
  }
  .header .region-leaderboard #block-menu-menu-mini a {
    font-size: 1rem;
    color: #fff;
  }
}

@media (min-width: 60em) {
  .header .region-leaderboard #block-menu-menu-mini a {
    position: relative;
    padding: 5px 0;
    color: #191919;
  }
  .header .region-leaderboard #block-menu-menu-mini a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 2px;
    background: #298aca;
    transition: all 0.25s ease;
  }
  .header .region-leaderboard #block-menu-menu-mini a:hover::after, .header .region-leaderboard #block-menu-menu-mini a:focus::after {
    width: 100%;
  }
}

.header .region-leaderboard #block-block-23 {
  width: auto;
}

.header .region-leaderboard #block-block-2 {
  width: auto;
  right: 0;
  margin: 0;
}

.header .region-leaderboard #weta {
  width: 60px;
  min-height: 30px;
}

.header .region-leaderboard #weta img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

@media (min-width: 60em) {
  .header .region-leaderboard #weta a {
    position: relative;
    padding: 8px 0;
    position: initial;
  }
  .header .region-leaderboard #weta a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 2px;
    background: #298aca;
    transition: all 0.25s ease;
  }
  .header .region-leaderboard #weta a:hover::after, .header .region-leaderboard #weta a:focus::after {
    width: 100%;
  }
}

.header .region-leaderboard #weta, .header .region-leaderboard #block-menu-menu-mini,
.header .region-leaderboard #block-block-41 {
  margin: 0;
}

.header .region-leaderboard .donate-button {
  background-color: #ed1c24;
  color: white;
  font-weight: normal;
  border: 1px solid #c61017;
  border-radius: 2px;
  box-shadow: rgba(255, 255, 255, 0.2) 0 0 8px inset, rgba(0, 0, 0, 0.05) 0 1px 2px;
  font-size: 1rem;
  text-transform: uppercase;
  text-shadow: 0 1px 1px #970c11;
  text-align: center;
  display: inline-block;
  line-height: 1;
  padding: 8px 16px;
  transition: all 0.25s ease;
  text-transform: capitalize;
  font-weight: bold;
  border-radius: 8px;
  border: 3px solid #ed1c24;
  line-height: inherit;
  text-shadow: none;
  font-family: multi-display, sans-serif;
  text-decoration: none;
}

.header .region-leaderboard .donate-button:hover {
  text-decoration: none;
  color: white;
  background-color: #c61017;
  background-image: -owg(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: -webkit(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: -moz(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: -o(compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false));
  background-image: compact(linear-gradient(center top, #c61017, #ed1c24), false, false, false, false, false, false, false, false, false);
}

.header .region-leaderboard .donate-button:active, .header .region-leaderboard .donate-button:focus {
  text-decoration: none;
  color: white;
  background-color: #68080c;
}

.header .region-leaderboard .donate-button:visited {
  color: white;
}

.header .region-leaderboard .donate-button .external-link-icon {
  position: relative;
  padding-right: 1em;
  width: 1em;
  height: 1em;
  line-height: 1em;
  overflow: hidden;
  display: inline-block;
  white-space: nowrap;
  text-indent: 2em;
  top: 0.2em;
  padding-right: 6px;
  vertical-align: text-top;
}

.header .region-leaderboard .donate-button .external-link-icon i {
  font-family: "Font Awesome 6 Pro";
  font-size: inherit;
  font-weight: normal;
  position: absolute;
  top: 0;
  right: 0;
}

.header .region-leaderboard .donate-button:hover, .header .region-leaderboard .donate-button:focus {
  color: #191919;
  box-shadow: none;
  text-shadow: none;
  background-color: #fff;
  border-color: #ed1c24;
  text-decoration: underline;
}

@media (min-width: 60em) {
  .header .region-leaderboard {
    margin-top: 6.666px;
  }
  .header .region-leaderboard .block + .block {
    margin-left: 30px;
  }
  .header .region-leaderboard .block-menu,
  .header .region-leaderboard .donate-button {
    margin-left: 15px !important;
  }
}

@media (min-width: 60em) and (max-width: 70.25em) {
  .header .header-regions {
    width: 100%;
  }
}

@media (min-width: 60em) and (max-width: 70.25em) {
  .header .header-regions .header-top-region {
    padding-top: 10px;
    padding-bottom: 20px;
  }
}

.header [id="block-search-form"] {
  margin-left: 0 !important;
  margin-bottom: 0;
}

.header [id="block-search-form"] input::placeholder {
  overflow: visible;
}

@media (min-width: 76.25em) {
  .header [id="block-search-form"] {
    margin-left: 13.5px;
  }
  .header [id="block-search-form"] input[type="search"] {
    font-size: 1rem;
  }
}

@media (min-width: 60em) {
  .header [id="block-search-form"] form {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
  }
  .header [id="block-search-form"] .form-item-search {
    position: relative;
  }
  .header [id="block-search-form"] .js-form-item,
  .header [id="block-search-form"] .form-actions {
    margin-top: 0;
  }
  .header [id="block-search-form"] label {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .header [id="block-search-form"] input[type="search"] {
    border: 0;
    border-radius: 0;
    width: 60px;
    padding: 11px 0 9px 0;
    line-height: 1;
    border-bottom: 2px solid transparent;
    transition: all 0.25s ease;
  }
  .header [id="block-search-form"] input[type="search"]::placeholder {
    color: #191919;
    font-weight: 700;
  }
  .header [id="block-search-form"] input[type="search"]:hover, .header [id="block-search-form"] input[type="search"]:focus {
    box-shadow: none;
    border-color: #298aca;
  }
  .header [id="block-search-form"] input[type="search"]:focus {
    width: 200px;
  }
  .header [id="block-search-form"] .form-actions {
    position: relative;
    width: 34px;
    height: 34px;
  }
  .header [id="block-search-form"] .form-actions::before {
    color: #298aca;
    content: "\f002";
    font-family: 'FontAwesome';
    font-weight: 400;
    background: transparent;
    pointer-events: none;
    font-size: 0.875rem;
    position: absolute;
    z-index: 3;
    height: 34px;
    width: 34px;
    line-height: 36px;
    text-align: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .header [id="block-search-form"] .form-actions:hover::before, .header [id="block-search-form"] .form-actions:focus-within::before {
    color: #191919;
  }
  .header [id="block-search-form"] input[type="submit"] {
    text-indent: -9999px;
    text-align: left;
    width: 34px;
    height: 34px;
    padding: 0;
    z-index: 2;
    background: transparent;
    border-color: transparent;
    transition: all 0.25s ease;
  }
  .header [id="block-search-form"] input[type="submit"]:hover, .header [id="block-search-form"] input[type="submit"]:focus {
    background-color: transparent;
    box-shadow: none;
    border-color: #298aca;
  }
  .header [id="block-search-form"] input[type="submit"]:focus {
    outline: 2px dotted;
    outline-offset: 2px;
    outline-color: #298aca;
  }
}

@media (min-width: 60em) and (max-width: 76.1875em) {
  .header [id="block-search-form"] {
    margin-top: 0;
    margin-left: 12px;
  }
  .header [id="block-search-form"] input[type="search"] {
    font-size: 0.9rem;
  }
  .header [id="block-search-form"] input[type="submit"] {
    height: 42px;
  }
}

@media (max-width: 59.9375em) {
  .header [id="block-search-form"] {
    display: none;
  }
  .show-search .header [id="block-search-form"] {
    display: block;
    position: fixed;
    top: 72px;
    left: 0;
    width: 100%;
    background: #298aca;
    z-index: 999;
    padding: 18px;
    background-color: #eff9ff;
  }
  .show-search.logged-in .header [id="block-search-form"] {
    top: 100px;
  }
  .header [id="block-search-form"] form {
    display: flex;
    margin: 0;
  }
  .header [id="block-search-form"] label {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
  }
  .header [id="block-search-form"] .form-item-search {
    margin-top: 0;
    flex: 1 1 auto;
  }
  .header [id="block-search-form"] .form-item-search input {
    font-size: 16px;
    padding: 3px 9px;
  }
  .header [id="block-search-form"] .form-actions {
    margin-left: 9px;
    margin-top: 0;
    position: relative;
  }
  .header [id="block-search-form"] .form-actions::before {
    content: "\f002";
    color: #fff;
    font-family: 'FontAwesome';
    font-weight: 100;
    background: transparent;
    pointer-events: none;
    font-size: 0.875rem;
    position: absolute;
    z-index: 3;
    height: 0.875rem;
    width: 0.875rem;
    line-height: 1.3;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }
  .header [id="block-search-form"] div {
    width: 100%;
  }
  .header [id="block-search-form"] input[type="search"] {
    width: 88%;
    height: 37px;
  }
  .header [id="block-search-form"] input[type="submit"] {
    text-indent: -9999px;
    text-align: left;
    width: 37px;
    height: 37px;
    padding: 0;
    z-index: 2;
    border-radius: 2px;
    background-color: #298aca;
    transition: all 0.25s ease;
  }
  .header [id="block-search-form"] input[type="submit"]:hover, .header [id="block-search-form"] input[type="submit"]:focus {
    background-color: #0071bc;
  }
}

.header .header [id="block-search-form"] label {
  color: #191919;
}

.header .header [id="block-search-form"] input[type="search"] {
  background: transparent;
  height: auto;
  font-size: 1rem;
  background-color: #fff;
}

.header .header [id="block-search-form"] input[type="search"]::placeholder {
  font-weight: bold;
  font-size: 1rem;
}

@media (min-width: 52.5em) and (max-width: 76.1875em) {
  .header .header [id="block-search-form"] input[type="search"] {
    font-size: 0.9rem;
  }
  .header .header [id="block-search-form"] input[type="search"]::placeholder {
    font-size: 0.9rem;
  }
}

.header [id="block-menu-menu-mini"] ul a {
  font-weight: 700;
  color: #191919;
  display: inline-block;
  text-align: right;
}

.header [id="block-menu-menu-mini"] ul a:hover, .header [id="block-menu-menu-mini"] ul a:focus {
  text-decoration: none;
}

@media (min-width: 76.25em) {
  .header [id="block-menu-menu-mini"] ul a {
    font-size: 1rem;
  }
}

@media (min-width: 60em) and (max-width: 76.1875em) {
  .header [id="block-menu-menu-mini"] ul a {
    font-size: 0.9rem;
  }
}

@media (min-width: 60em) {
  .header [id="block-menu-menu-mini"] {
    margin-left: 20px;
  }
  .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a {
    padding: 8px 0;
    position: relative;
  }
  .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 0;
    height: 2px;
    background: #298aca;
    transition: all 0.25s ease;
  }
  .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a:hover::after, .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a:focus::after {
    width: 100%;
  }
  .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a:hover::after, .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a:focus::after {
    width: 100%;
  }
  .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a.is-active::after {
    width: 1em;
  }
  .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a.is-active:hover::after, .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a.is-active:focus::after {
    width: 100%;
  }
}

@media (min-width: 60em) and (max-width: 59.9375em) {
  .header [id="block-menu-menu-mini"] [id="mega-menu"] > ul > li > a {
    font-size: 1.3rem;
  }
}

.toggle-menu {
  right: 0;
  margin-left: 0;
  border: none;
  background: transparent;
}

.toggle-menu .bars {
  display: block;
  padding-top: 2px;
  padding-bottom: 1px;
  height: 1rem;
  margin-bottom: 0.25rem;
}

.toggle-menu .bar {
  content: "";
  display: block;
  width: 16px;
  height: 2px;
  border-radius: 2px;
  margin: 0 auto;
  background-color: #0071bc;
}

.toggle-menu .bar + .bar {
  margin-top: 3px;
}

.toggle-menu:hover {
  cursor: pointer;
}

.toggle-menu:hover .bar {
  background-color: #298aca;
}

.toggle-menu:hover .bar.first {
  transform: rotate(45deg) translate(6.5px, 9.5px);
  width: 9px;
}

.toggle-menu:hover .bar.middle {
  transform: rotate(90deg) translate(0, -0.25px);
  width: 16px;
}

.toggle-menu:hover .bar.last {
  transform: rotate(135deg) translate(-1.5px, -3px);
  width: 9px;
}

.show-nav .toggle-menu {
  background-color: #eff9ff;
  color: #0071bc;
}

.show-nav .toggle-menu .menu-text {
  color: #0071bc;
}

.show-nav .toggle-menu .bar {
  background-color: #0071bc;
  width: 18px;
}

.show-nav .toggle-menu .bar.first {
  transform: rotate(45deg) translate(5px, 5px);
}

.show-nav .toggle-menu .bar.middle {
  display: none;
}

.show-nav .toggle-menu .bar.last {
  transform: rotate(-45deg) translate(-1px, 1.5px);
}

.show-nav .toggle-menu:hover .bar {
  background-color: #0071bc;
}

.show-nav .toggle-menu:hover .bar.first {
  transform: rotate(-45deg) translate(-2px, -1.5px);
  width: 9px;
}

.show-nav .toggle-menu:hover .bar.middle {
  display: block;
  transform: rotate(90deg) translate(0, 0);
  width: 16px;
}

.show-nav .toggle-menu:hover .bar.last {
  transform: rotate(45deg) translate(-5px, -9px);
  width: 9px;
}

.mobile-toggle {
  color: #0071bc;
  position: relative;
  font-size: 0.9rem;
  font-family: multi-display, sans-serif;
  font-weight: 700;
  text-decoration: none;
  border: none;
  border-radius: 8px;
  text-align: center;
  background: none;
  padding: 9px;
  margin: 9px;
  width: 70px;
  height: 54px;
  transition: all 0.25s ease;
}

.mobile-toggle .menu-text {
  display: block;
  line-height: 1;
}

.mobile-toggle:hover, .mobile-toggle:focus {
  background-color: #eff9ff;
  color: #298aca;
}

@media (min-width: 48em) {
  .mobile-toggle {
    display: block;
  }
}

@media (min-width: 60em) {
  .mobile-toggle {
    display: none;
  }
}

@media (max-width: 59.9375em) {
  .header-regions {
    display: none;
    max-height: 95vh;
    max-height: calc(100% - 80px);
    height: calc(100% - 66px);
    overflow-y: auto;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    left: 0;
    right: 0;
  }
  .header-regions a:focus {
    outline-color: #fff;
  }
  .header-regions .header-top-region,
  .header-regions .header-bottom-region {
    width: 100%;
  }
  .header-regions .header-top-region {
    position: relative;
    z-index: 2;
  }
}

@media (max-width: 59.9375em) and (max-width: 59.9375em) {
  .header-regions .header-bottom-region {
    width: 100%;
    flex-grow: 2;
    position: relative;
    z-index: 1;
    display: block;
  }
}

@media (max-width: 59.9375em) and (min-width: 52.5em) {
  .header-regions .header-bottom-region {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 59.9375em) and (min-width: 60em) {
  .header-regions .header-bottom-region .mainnavigation > ul {
    display: flex;
    justify-content: space-between;
  }
}

@media (max-width: 59.9375em) {
  .header-regions [id="block-menu-menu-mini"] > ul a {
    color: #fff;
  }
}

@media (max-width: 59.9375em) {
  body.show-nav {
    height: 100%;
    overflow-y: hidden;
  }
  body.show-nav .header-regions {
    background-color: #0071bc;
    color: #fff;
    top: 100px;
    display: block;
    z-index: 25;
    padding: 20px;
  }
  body.show-nav .header-regions a {
    color: #fff;
  }
  body.show-nav .header-regions a.focus-visible {
    outline-color: #fff;
  }
  body.show-nav.not-logged-in .header-regions {
    top: 72px;
  }
}

@media (min-width: 60em) and (max-width: 70.25em) {
  .header .logo--main {
    position: absolute;
    top: 10px;
    min-width: 175px;
    max-width: auto;
  }
  .header .header-content {
    width: 100%;
  }
  .header .header-bottom-region {
    display: block;
  }
  .header .header-bottom-region .mainnavigation > ul {
    display: flex;
    justify-content: space-between;
  }
}

.expanded .toggle-submenu {
  appearance: none;
  border: none;
  background-color: transparent;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
}

.expanded .toggle-submenu span {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

.expanded .toggle-submenu i {
  color: #a40610;
  font-size: 1.25rem;
}

.callout-box {
  box-sizing: border-box;
  width: 100%;
  clear: both;
  display: block;
  border: 0.5px solid #ccc;
  background: #eff9ff;
  padding: 20px 20px 6.6666666667px;
  margin: 20px 0;
}

.callout-box ul li:before {
  background-color: black;
}

.sidebar-box {
  float: right;
  width: 100%;
  border-top: 2px solid #ccc;
  padding-top: 10px;
}

.sidebar-box ul {
  margin-left: 14px;
}

.sidebar-box ul li:before {
  background-color: black;
}

.sidebar-box h3 {
  margin-bottom: 10px;
}

.sidebar-box h4 {
  margin-bottom: 10px;
  color: #298aca;
  font-weight: normal;
  font-family: "open-sans", Arial, Helvetica, sans-serif;
  font-size: 1.188rem;
}

@media (min-width: 30em) {
  .sidebar-box {
    max-width: 215px;
    margin-left: 20px;
  }
}

@media (max-width: 47.9375em) {
  body:not(.front) #content-wrapper #content-column,
  body:not(.front) #content-wrapper .sidebar {
    width: calc(100% - 50px);
    float: none;
    display: block;
    margin: 0 auto;
  }
  body:not(.front) #content-wrapper #content-column .block-inner,
  body:not(.front) #content-wrapper .sidebar .block-inner {
    margin: 0;
  }
}

@media (min-width: 48em) {
  body:not(.front) #content-wrapper {
    width: calc(100% - 40px);
    display: block;
    margin: 0 auto;
  }
}

.region-content > div > .block-inner {
  margin-left: 20px;
  margin-right: 20px;
}

#block-panels-mini-footer-menu {
  font-size: 1rem;
  padding: 36px 0 18px;
}

#block-panels-mini-footer-menu a {
  text-decoration: none !important;
  display: inline-block;
}

#block-panels-mini-footer-menu a:hover, #block-panels-mini-footer-menu a:focus {
  text-decoration: underline !important;
}

#block-panels-mini-footer-menu a.highlight {
  color: #ffde16;
  padding-bottom: .5em;
}

#block-panels-mini-footer-menu a.nolink {
  pointer-events: none;
}

#block-panels-mini-footer-menu a.nolink:hover, #block-panels-mini-footer-menu a.nolink:focus {
  text-decoration: none !important;
}

#block-panels-mini-footer-menu h2 {
  font-size: 1rem;
  color: #ffde16;
  padding: 0 0 .5em;
  margin: 0;
  font-family: "open-sans", Arial, Helvetica, sans-serif;
}

#block-panels-mini-footer-menu li {
  list-style-type: none;
  list-style-image: none;
  padding-bottom: .5em;
}

#block-panels-mini-footer-menu .column {
  display: block;
}

#block-panels-mini-footer-menu .space-top {
  padding-top: 27px;
}

@media (min-width: 30em) and (max-width: 52.4375em) {
  #block-panels-mini-footer-menu .column {
    width: 50%;
    padding-top: 27px;
  }
  #block-panels-mini-footer-menu .column:nth-child(2), #block-panels-mini-footer-menu .column:nth-child(3) {
    margin-left: -5px;
  }
}

@media (max-width: 29.9375em) {
  #block-panels-mini-footer-menu .column {
    width: 100%;
  }
  #block-panels-mini-footer-menu .column + .column {
    padding-top: 27px;
    margin-left: -5px;
  }
}
