// =============================================================================
// TYPOGRAPHY
// =============================================================================

// Adjust with copy paste of pixel css code from http://www.gridlover.net/app/
$base-font-size: 16px; // The font size set on the root html element & used in rem() mixin calculations
$measure: 760px;
$h1-font-size: 3rem;
$h1-font-size-mobile: 1.875rem;
$h1-font-size-widescreen: 2.5rem;
$h1-line-height: 1.25;
$h1-margin-top: $horiz-gutter;
$h1-margin-bottom: $vert-gutter*0.6666;
$h2-font-size: 1.55rem;
$h2-font-size-mobile: 1.563rem;
$h2-font-size-widescreen: 1.8rem;
$h2-line-height: 1.25;
$h2-margin-top: $horiz-gutter * 1.25;
$h2-margin-bottom: $horiz-gutter*0.4;
$h3-font-size: 1.2rem;
$h3-font-size-mobile: 1.359rem;
$h3-font-size-widescreen: 1.5rem;
$h3-line-height: 1.4;
$h3-margin-top: $horiz-gutter;
$h3-margin-bottom: $horiz-gutter*0.3333;
$h4-font-size: 1.188rem;
$h4-font-size-mobile: 1.063rem;
$h4-font-size-widescreen: 1.313rem;
$h4-line-height: 1.4;
$h4-margin-top: $vert-gutter*0.6666;
$h4-margin-bottom: $vert-gutter*0.25;

// Variables are rounded to pixels
// It's safe to multiply with integers
// eg. h1 {margin: 2*$h1-line 0;}
// The grid will stay aligned

// **** END GRID LOVER VARIABLES ******

// NOTE: $base-font-size & $base-line-height are also Compass variables that have default values.
// http://compass-style.org/reference/compass/typography/vertical_rhythm/
$base-line-height: $base-font-size * 1.5; // Typically 1.2-1.5 dependant upon size of font characters.
$body-line-height: $base-line-height / $base-font-size;

// Common font stacks
$serif: Times, "Times New Roman", Georgia, "DejaVu Serif", serif;
$sans-serif: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue",
  Helvetica, Arial, "Lucida Grande", sans-serif;
$courier: monospace, "Courier New", "DejaVu Sans Mono", sans-serif;
$webfont-normal: "FONTFACENAMEHERE", Arial, Helvetica, sans-serif;
$webfont-bold: "FONTFACENAMEHERE", Arial, Helvetica, sans-serif;
$prenton: prenton, Arial, Helvetica, sans-serif;
$myriad: myriad-pro, Arial, Helvetica, sans-serif;
$open-sans: "open-sans", Arial, Helvetica, sans-serif;
$multi: multi-display, sans-serif;
$verveine: "verveine", sans-serif;
$body-font: $open-sans;
$title-font: $multi;

// =============================================================================
// COLOR PALLETE
// =============================================================================

$gray-dark: #292929; // Headings
$gray-darker: #3c3b3b; // Body text
$gray-lighter: #eeeeee; // Disabled background
$gray-light: #888888; // Disabled font
$blue-gray: #739bc7; // Links & Buttons
$red: #e02443; // Error / delete
$yellow: #dadd25; // Warning
$green: #25db4c; // Success
$black: #000;
$swab-blue: #298aca; /* medium blue */
$swab-dark-blue: #0071bc; /* dark blue */
$swab-deep-blue: #2e3192; /* deep blue */
$swab-light-blue: #29abe2; /* new light blue */
$swab-pale-blue: #eff9ff;
$swab-pale-blue-transparent: rgba(239, 249, 255, 0.95);
$swab-yellow: #fab74b; /* yellow gold */
$swab-bright-yellow: #ffde16; /* Reading Rockets yellow */
$swab-light-yellow: #fff9e1;
$swab-red: #ed1c24; /* red */
$swab-dark-red: #a40610; /* dark red */
$swab-gray: #ccc;
$swab-dark-gray: #464646;
$swab-beige: #faf3df;
$swab-black: #191919;
$primary-button-color: $swab-dark-blue;

// =============================================================================
// GLOBAL VARIABLES
// =============================================================================

// SHARED FORM VARIABLES
$ui-border-color: lighten($blue-gray, 28%);
$ui-background-color: lighten($blue-gray, 32%);
$ui-padding: ($vert-gutter/2) ($horiz-gutter/2);
$ui-border-radius: 2px;
$thin-border: 1px solid $black;
$thick-border: 3px solid $black;
$shadow-color: rgba(92, 95, 97, 0.25);
$box-shadow: 0 4px 12px $shadow-color;

// SHARED STATES
$delete-color: $red; // delete & remove buttons / inputs
$disabled-background-color: $gray-lighter;
$disabled-font-color: $gray-light;

// =============================================================================
// ATOM (element) VARIABLES
// =============================================================================

// TEXT
$header-font: $multi;
$page-title-color: $swab-black;
$block-title-color: $page-title-color;
$font-monospace: $courier; // <pre>, <code> tags

// BODY
$body-font-color: $swab-black;
$body-bgrd-color: #ffffff;

// LINKS
$link-color: $blue-gray;
$link-color-visited: darken($link-color, 10%);
$link-color-hover: darken($link-color, 5%);
$link-color-focus: darken($link-color, 10%);
$link-color-active: darken($link-color, 10%);
$link-text-decoration: none;

// FORM INPUTS, BUTTONS & SUBMITS
$input-padding: 8px 16px;
$input-font-family: inherit;
$input-font-color: $body-font-color;
$input-font-size: 14px;
$input-background-color: #fff;
$input-backgroud-color-focus: #fff;
$input-border-color: $ui-border-color;
$input-border-color-focus: $link-color;
$input-border-radius: $ui-border-radius;
$input-box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
$input-box-shadow-focus: 0 0 2px $link-color;
$input-transition-duration: 0.1s;
$input-disabled-background-color: $disabled-background-color;
$input-disabled-font-color: $disabled-font-color;
$width-xsmall: 60px;
$width-small: 120px;
$width-medium: 180px;
$width-large: 240px;
$width-xlarge: 300px;
$width-xxlarge: 100%;
$inline-field-gutter: 0.5rem;
$inline-field-gutter: 0.5rem;
$placeholder-color: #999999;
$placeholder-focus-color: $input-background-color;

// BUTTONS
$button-color: $link-color;
$button-border: #dddddd;
$button-border-radius: $ui-border-radius;

// BLOCKQUOTES
$blockquote-border-color: $ui-border-color;

// HORIZONTAL RULE
$horizontal-rule-border: $ui-border-color;

// =============================================================================
// MOLECULE / ORGANISM (module / components) VARIABLES
// =============================================================================

// MESSAGES
$success-background: $green;
$success-border: darken($success-background, 25%);
$success-text: $success-border;
$warning-background: $yellow;
$warning-border: darken($warning-background, 25%);
$warning-text: $warning-border;
$error-background: $red;
$error-border: darken($error-background, 25%);
$error-text: $error-border;

// PAGER
$pager-color: $link-color;
$pager-color-hover: $link-color-hover;

// FIELDSET
$legend-font-weight: bold;

// FORM ITEMS
$form-item-margin: 0 0 $horiz-gutter 0;
$form-item-label-font-weight: bold;
$form-item-label-font-size: 13px;
$form-item-label-margin: 0 0 0 0;
$form-item-description-font-size: 13px;

// TABLES
$table-tbody-border-style: solid;
$table-tbody-border-width: 1px 1px 1px 1px;
$table-tbody-border-color: darken($body-bgrd-color, 2%) transparent transparent
  transparent;
$table-th-border-style: solid;
$table-th-border-width: 1px 1px 1px 1px;
$table-th-border-color: transparent transparent darken($body-bgrd-color, 2%)
  transparent;
$table-tr-even-background: lighten($body-bgrd-color, 2%);
$table-tr-even-border-style: solid;
$table-tr-even-border-width: 1px 1px 1px 1px;
$table-tr-even-border-color: darken($body-bgrd-color, 2%) transparent
  darken($body-bgrd-color, 2%) transparent;
$table-tr-odd-background: transparent;
$table-tr-odd-border-style: solid;
$table-tr-odd-border-width: 1px 1px 1px 1px;
$table-tr-odd-border-color: darken($body-bgrd-color, 2%) transparent transparent
  transparent;
$table-tr-drag-background: $table-tr-odd-border-color;
$table-td-padding: 0.25rem 0.5rem;
$table-td-disabled-background: #ccc;

// DRUPAL EDIT, VERTICAL & HORIZONTAL TABS
$tabs-background-color: $ui-background-color;
$tabs-background-color--active: $link-color;
$tabs-border-color: $ui-border-color;
$tabs-border-color--active: $link-color;
$tabs-content-color: $body-font-color;
$tabs-content-color--active: #ffffff;
$tabs-border-radius: $ui-border-radius;
$tabs-font-size: 1rem;
