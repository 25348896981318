/* Global changes */

#page {
  overflow: hidden;
}

/* New Navigation */

[id="nav-wrapper"] {
  box-shadow: none !important;

  * {
    box-sizing: border-box;
  }

  nav {
    margin: 0px !important;
  }

  .clearfix {
   &::before,
   &::after {
      content: "";
      display: none;
    }

    &:after {
      clear: both;
    }
  }

  [id="mega-menu"] {
    display: flex;
    flex-wrap: wrap;

     @include breakpoint($desktop-medium-max) {
        padding: 0 $horiz-gutter*0.5;

        li {
          width: 100%;

          &.is-active {
            i {
              transform: rotate(0deg);
            }
          }
        }
      }

    > li {
      + li {
        @include breakpoint($desktop-medium-min) {
          margin-left: $horiz-gutter*0.75;
        }

        @include breakpoint($menu-desktop-min) {
          margin-left: $horiz-gutter;
        }
      }

      @include breakpoint($desktop-medium-max) {
        border-top: 1px solid rgba(288, 288, 288, 0.5);
      }

      > a {
        font-size: 18px;
        color: #fff;
        position: relative;
        height: 3em;
        line-height: 3em;
        font-family: $multi;
        font-weight: bold;
        display: flex;
        text-decoration: none;

        @include breakpoint($desktop-medium-min) {
          color: $swab-black;
        }

        i {
          width: 18px;
          height: 18px;
          position: relative;
          text-align: right;
          color: #fff;

          @include breakpoint($desktop-medium-min) {
            color: $swab-dark-red;
          }
        }

        @include breakpoint($desktop-medium-max) {
          justify-content: space-between;
          align-items: center;
          font-size: 1.2rem;

          i {
            transform: rotate(270deg);
          }
        }

        @include breakpoint($desktop-medium-min) {
          @include hover-underline;

          i {
            margin: auto;
          }
        }
      }

      .dropdown {
        display: none;

        div {
          display: block !important;
        }

        .layout-container {
          @include layout-container;
        }

        .megamenu__links {
          float: left;
          width: 100%;
          @include clearfix;

          li {
            margin: 0;

            a {
              font-weight: bold;
              font-size: 1rem;
              display: block;
              position: relative;
              text-decoration: none;
            }
          }
        }

        .megamenu__image {
          width: 25%;
          float: left;
          padding-right: $vert-gutter*0.5;

          img {
            border-radius: $ui-border-radius;
          }
        }

        .megamenu__text {
          color: #fff;
          width: 37.5%;
          float: left;
          font-size: 1rem;
          line-height: 1.5;

          h2 {
            color: #fff;
            font-weight: bold;
            font-family: $multi;
            padding-bottom: $horiz-gutter*0.5;
            margin-top: 0;
          }

          .button {
            @include transition;

            font-size: 1rem;
            font-family: $multi;
            font-weight: bold;
            background-color: $swab-yellow;
            color: $swab-black !important;
            width: auto;
            text-decoration: none;
            display: inline-block;
            padding: $horiz-gutter*0.5 $horiz-gutter;
            border: 2px solid $swab-yellow;

            &:hover,
            &:focus {
              background-color: #fff;
              text-decoration: underline;
              border: 2px solid $swab-black;
            }
          }
        }

        @include breakpoint($desktop-medium-max) {
          .megamenu__text,
          .megamenu__image {
            display: none !important;
          }
        }

        @include breakpoint($desktop-medium-min) {
          position: absolute;
          z-index: 30;
          width: 100vw;
          right: -$horiz-gutter*0.75;
          background-color: $swab-dark-blue;
          padding: $horiz-gutter*3 0;

          .desktop-small {
            display: none;
          }

          .megamenu__links {
            a {
              font-size: 0.9rem;
            }
          }

          &.no-image {
            .megamenu__links {
              width: 70%;
              padding-left: $vert-gutter*0.5;

              ul {
                columns: 3;
                column-gap: 18px;
              }
            }

            .megamenu__text {
              width: 30%;
            }
          }

          &.has-image {
            .megamenu__links {
              width: 37.5%;
              padding-left: $vert-gutter*0.5;
            }
          }
        }
      }
    }

    @include breakpoint($desktop-medium-min) {
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;

      li {
        padding: 0;

        > a {
          font-size: 92%;
        }
      }
    }

    @include breakpoint($menu-stacked-min) {
      justify-content: flex-end;
    }

    @include breakpoint($desktop-min) {
      li {
        > a {
          font-size: 17px;
        }
      }
    }

    @include breakpoint($menu-desktop-min) {
      li {
        > a {
          font-size: 18px;
        }
      }
    }
  }
}

#nav-wrapper .menu li:hover > a {
  text-decoration: none;
}

#nav-wrapper .menu li.expanded ul {
  display: none;
  position: absolute;
  top: 3.1875em;
  padding: 15px 25px 25px;
  z-index: 99;
  background-color: $swab-blue;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}

#nav-wrapper .menu li.expanded.megamenu ul {
  left: 0;
  -webkit-column-count: 3; /* Chrome, Safari, Opera */
  -moz-column-count: 3; /* Firefox */
  -moz-columns: 3; /* Firefox */
  column-count: 3;
  -moz-column-fill:balance;
  -webkit-column-gap: 25px; /* Chrome, Safari, Opera */
  -moz-column-gap: 25px; /* Firefox */
  column-gap: 25px;
  max-height: 375px;
  transform: none;

  li {
    display: inline-block;
  }
}

#nav-wrapper .menu li.expanded > a:before {
  content: "";
  background-image: url('../images/angle-down.svg');
  background-size: contain;
  background-repeat: no-repeat;
  width: 20px;
  height: 20px;
  display: inline-block;
  margin-left: 5px;
  position: relative;
  align-self: center;
}

#nav-wrapper .menu li:hover ul,
#nav-wrapper .menu li ul li:hover,
#nav-wrapper .menu li:hover ul li:hover {
  display: block;
}

#nav-wrapper .menu li ul li {
  display: block;
  width: 100%;
}

#nav-wrapper .menu li ul li a {
  color: #fff;
  display: block;
  width: 100%;
  padding: $vert-gutter*0.25 0;
  font-weight: bold;

  @include breakpoint($desktop-medium-min) {
    border-bottom: 1px solid #fff;
  }
}

#nav-wrapper .menu li ul li a:hover {
  text-decoration: underline;
}

#mobile-menu {
  display: none;
}

.three-stars {
  background: url(../images/3stars_white.png) no-repeat 0 center;
  padding-left: 60px !important;
  background-size: 57px;
}

.nolink > a {
  pointer-events:  none;
  cursor: default;
}



/* Social Link Styles */

.social-links {
  text-align: center;
  a.imgmenu {
    width: 32px;
    height: 32px;
    text-indent: -9999px;
    margin:20px 0 0 10px;
    display: inline-block;
    text-align: center;
  }
}

.social-links a#fb {
  background: transparent url(../images/fb_res.png) no-repeat center center;
  background-size:contain;
}
.social-links a#twitter {
  background: transparent url(../images/twitter_res.png) no-repeat center center;
  background-size:contain;
}
.social-links a#youtube {
  background: transparent url(../images/youtube_res.png) no-repeat center center;
  background-size:contain;
}
.social-links a#rss {
  background: transparent url(../images/rss_res.png) no-repeat center center;
  background-size:contain;
}

.social-links a#pinterest {
  background: transparent url(../images/pinterest.png) no-repeat center center;
  background-size:contain;
}

/* Button styles */

.cta-button {
  clear: both;
  padding-top: 30px;
}
.button {
  display: block;
  width: 33.3333%;
  margin: 0 auto;
  text-align: center;
  color: white !important;
  text-decoration: none !important;
  font-size: 18px;
  font-weight: bold;
  border-radius: 8px;
  background-color: $swab-light-blue;
  font-family: $title-font;
  padding: 8px 10px;
  box-sizing: border-box;
  transition: all 0.25s ease;
  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.red-button {
  background-color: #ed1c24; /* red */
}

.red-button:hover {
  background-color: #A40610; /* dark red */
}

/* Home Image Panel Styles */

.home-panel-strip {
}

.image-strip {
  width: 100vw !important;
  max-width: 100vw !important;
  background: url(../images/swab-cloudsbkgrd.png) no-repeat 0 center;
  background-size: cover;
  margin-left: calc(-100vw / 2 + 940px / 2) !important;
  margin-right: calc(-100vw / 2 + 940px / 2) !important;
  img {
    display: block;
    margin: 0 auto;
    max-width: 960px;
  }
}
.bottom-strip {
  color: red;
  padding: 20px 20px 0px;
  text-align: center;
  font-size: 2em;
  letter-spacing: 0.25px;
  font-family: $body-font;
  p {
    margin-bottom: 5px;
  }
}


/* New Home Styles */

.home-panels {
  display: none !important;
}

.home-row {
  padding: 30px 0px !important;
  border-bottom: 1px dotted black;
  &:last-child {
    border-bottom: 0px;
  }
  &.row-first {
    padding-top: 0px !important;
  }
  .panels-flexible-region {
    @include breakpoint($phone-max) {
      .panel-pane:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

.one-third,
.pane-bundle-featured-content,
.pane-bundle-featured-video {
  width: 33.3333%;
  float: left;
  box-sizing: border-box;
  padding: 0px 15px;
}

.negative-margin {
  margin-left: -15px;
  margin-right: -15px;
  width: calc(100% + 30px);
}

img[width="625"] { width: 100% !important; }

/* Intro Blocks */

.intro-blocks {
  text-align: center;
  img {
    max-width: 50%;
    text-align: center;
    margin: 0 auto;
    box-sizing: border-box;

    @include breakpoint($phone-max) {
      max-width: 115px;
    }
  }
  h2,
  p {
    text-align: left;
  }
  h2 {
    padding-bottom: 10px;
    margin-top: 0;

    @include breakpoint($phone-min) {
      margin-top: 15px;
    }
  }
}

.panels-flexible-region-4-intro_blocks {
  .one-third {
    @include breakpoint($phone-max) {
      padding: 0 !important;
    }
  }
}

/* Featured Content and Featured Video */

.pane-bundle-featured-content,
.pane-bundle-featured-video {
  display: flex;
  flex-wrap: wrap;
}

.pane-bundle-featured-video .field-name-field-featured-video-image {
  margin-bottom: 10px;
}

.field-name-field-featured-kicker {
  color: $swab-black;
  font-family: $body-font;
  font-weight: bold;
}

.reading-tips-image {
  position: relative;
  top: -0.76vw;
}

.pane-bundle-featured-content img {
  width: 100%;
  height: auto;
}

.pane-bundle-featured-content .pane-title,
.pane-bundle-featured-video .pane-title,
.view-related-blogs .pane-title {
  display: block !important;
  order: 2;
  color: $swab-dark-blue;
  margin-top: 0;
}

.pane-bundle-featured-content .pane-content,
.pane-bundle-featured-video .pane-content {
  order: 1;
  width: 100%;
}

.pane-bundle-featured-content .pane-title a,
.pane-bundle-featured-content .pane-title a:visited,
.pane-bundle-featured-video .pane-title a,
.pane-bundle-featured-video .pane-title a:visited,
.view-related-blogs .pane-title a,
.view-related-blogs .pane-title a:visited {
  color: $swab-dark-blue;
  text-decoration: none;
}

.pane-bundle-featured-content .pane-title a:hover,
.pane-bundle-featured-content .pane-title a:active
.pane-bundle-featured-video .pane-title a:hover,
.pane-bundle-featured-video .pane-title a:active,
.view-related-blogs .pane-title a:hover,
.view-related-blogs .pane-title a:active {
  color: #29abe2; /* new light blue */
  text-decoration: none;
  font-family: $title-font;
}

// related blog views block override

.pane-bundle-featured-video .player {
  position: relative;
  height: 0;
  overflow: hidden;
  padding-bottom: 55%;
  margin-bottom: 5px;
}
.pane-bundle-featured-video .player iframe {
  width: 100% !important;
  height: 100% !important;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* Video Popup */
.pane-bundle-featured-video .pane-title {
  cursor: pointer;
}

.field-name-field-featured-video-image {
  img {
    @include transition(opacity 0.3s ease);
    cursor:pointer;
    opacity:0.95;
    &:hover {
      opacity:1;
    }
  }
}

.field-name-field-featured-video {
    display:none;
    position:fixed;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,0.75);
    z-index:500;
    .field-item {
      max-width:960px;
      margin:0 auto;
      padding-top:48px;
    }
    iframe {
      border:1px solid #fff;
      box-shadow:1px 4px 4px rgba(0,0,0,0.5);
      -webkit-box-shadow:1px 4px 4px rgba(0,0,0,0.5);
      -moz-box-shadow:1px 4px 4px rgba(0,0,0,0.5);
      box-sizing: border-box;
    }
  }


/* Testimonial */
.view-testimonials {
  text-align: center;
  color: $swab-light-blue;
  font-family: $title-font;
  font-weight: 400;

  .views-field-body {
    font-size: 1.5em;
  }
  .views-field-field-testimonial-author {
    margin-top: 20px;
    color: $swab-dark-blue;
    font-size: 1.2em;
    font-family: $body-font;
  }
}


/* Footer */
#footer-wrapper,
#footer-bottom {
  font-size: 0.9rem;

  h2, p {
    color: #fff;
  }

  .container {
    @include layout-container;
    padding-top: $horiz-gutter*2.5;
    padding-bottom: $horiz-gutter;
  }

  @include breakpoint($desktop-medium-max) {
    .container {
      padding-left: 0;
      padding-right: 0;
    }

    .region-footer {
      padding: 0 $horiz-gutter;
    }
  }
}

#footer-bottom {
  .container {
    padding-top: $horiz-gutter*1.5;
    padding-bottom: $horiz-gutter*0.5;
  }
}

#footer-wrapper {
  .region-footer {
    #block-block-38,
    #block-block-48,
    #block-block-47 {
      width: 100%;
    }

    h2 {
      font-size: $h3-font-size;
      margin-top: 0;
    }

    #block-block-40 {
      display: none;
    }

    #block-block-47 {
      p {
        margin-bottom: 6px;
      }
    }

    @include breakpoint($tablet-small-min) {
      #block-block-47,
      #block-block-48 {
        width: 46%;
      }

      #block-block-47 {
        padding-right: $horiz-gutter;
      }

      #block-block-48 {
        padding-left: $horiz-gutter;
      }
    }

    @include breakpoint($desktop-medium-min) {
      #block-block-38,
      #block-block-48,
      #block-block-47 {
        width: 33%;
      }

      #block-block-38 {
        padding-right: $horiz-gutter;
      }

      #block-block-48 {
        padding: 0 $horiz-gutter;
      }

      #block-block-47 {
        padding-left: $horiz-gutter;
        width: 20%;
      }
    }

    @include breakpoint($desktop-min) {
      #block-block-47 {
        width: 33%;
      }
    }
  }

  img {
    margin-bottom: $horiz-gutter;
    max-width: 150px;
  }

  .logo-footer {
    img {
      max-width: 200px;
    }
  }

  #block-block-47 {
    img {
      max-width: 100px;
      margin-bottom: $horiz-gutter;
    }
  }

  a:not(.logo-footer) {
    img {
      margin-top: $horiz-gutter;
      margin-bottom: $horiz-gutter*2;
    }
  }

  .mailchimp-subscribe {
    .js-form-type-checkbox,
    .js-form-type-radio {
      .form-item-wrapper {
        @include checkbox-radio($size: small);
      }
    }

    .visually-hidden {
      @include visually-hidden;
    }

    ::placeholder {
      color: $swab-black;
    }

    input[type=email] {
      font-family: $body-font;
      font-size: 1rem;
      border: 2px solid $swab-black;
      border-radius: 4px;
      background-color: #fff;
      color: $swab-black;
      height: 38px;
      width: 95%;
      line-height: 1;
      padding: 4px 10px;
      margin-bottom: $horiz-gutter*0.5;
    }

    .button--secondary {
      @include button--submit($btn-color: $swab-red);
      @include transition;
      appearance: none;
      text-transform: capitalize;
      font-weight: bold;
      border-radius: 4px !important;
      border: 2px solid #fff !important;
      line-height: inherit;
      text-shadow: none;
      font-family: $title-font;
      text-decoration: none;
      margin-top: $horiz-gutter*0.5;

      &:hover,
      &:focus {
        color: $swab-black !important;
        cursor: pointer;
        box-shadow: none;
        text-shadow: none;
        background-color: #fff !important;
        border-color: $swab-black !important;
        text-decoration: underline;
      }
    }
  }
}

#footer-bottom {
  background-color: $swab-black;
  #block-block-11 {
    p + p {
      margin-bottom: 0;
    }

    p {
      @include breakpoint($tablet-portrait-max) {
        text-align: center;
        margin-top: $horiz-gutter*0.5;
      }
    }
  }

  .container {
    padding-left: 0;
    padding-right: 0;

    .block-inner {
      margin: 0;
    }
  }

  .region-footer-bottom {
    padding: 0 $horiz-gutter*1.25;

    @include breakpoint($tablet-portrait-min) {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    @include breakpoint($desktop-min) {
      padding: 0 $horiz-gutter*0.5;
    }
  }

  a {
    color: #fff;
  }
}

.region-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  @include breakpoint($desktop-medium-min) {
    flex-wrap: nowrap;
  }

  a {
    text-decoration: underline;

    &:hover,
    &:focus {
      text-decoration: none;
    }
  }
}

.external-link-icon {
  width: 0.825em;
  margin-left: 0.25em;

  .visually-hidden {
    @include visually-hidden;
  }
}

.social-links {
  a {
    background-color: $swab-dark-blue;
    color: #fff;
    display: block;
    text-indent: -9999px;
    text-align: right;
    padding: 0 !important;
    overflow: hidden;
    width: 44px;
    height: 44px;
    position: relative;
    display: inline-block;
    margin: 0;

    + a {
      margin-left: 5px;
    }
  }

  .facebook {
    background: transparent url(../images/fb_res.png) no-repeat center center;
    background-size:contain;
  }

  .pinterest {
    background: transparent url(../images/pinterest.png) no-repeat center center;
    background-size:contain;
  }

  .twitter {
    background: transparent url(../images/twitter_res.png) no-repeat center center;
    background-size:contain;
  }

  .youtube {
    background: transparent url(../images/youtube_res.png) no-repeat center center;
    background-size:contain;
  }

  @include breakpoint($tablet-portrait-max) {
    text-align: center;
    margin-top: $horiz-gutter*0.5;
  }
}

/* DC For Kids */
.view-dc-for-kids {
  .views-field-field-url {
    display: inline-block;
    a {
      color: $swab-dark-blue;
      &:hover {
        color: $swab-deep-blue;
      }
    }
  }
  .views-field-field-subscrip {
    display: inline-block;
  }
}

.in-this-section {
  clear: both;
  border-top: 1px solid #ccc;
  padding-top: 20px;
}

.left {
  overflow: hidden;
}

.credit {
  display: block;
  position: relative;
  top: -5px;
}

/* Topics Pages */

.contentbox {
  padding-top: 15px;
  margin-top: $horiz-gutter*2;

  h2 {
    margin-top: 0;
  }
  .row {
    .box {
      width: calc(25% - 1.5em);
      &:last-child {
        margin-right: 0px;
      }
      img {
        width: 100%;
        height: auto;
      }
      a {
        display: block;
      }
      span {
        background: none !important;
        color: $swab-red !important;
      }
    }
  }
}

.bookbox {
  overflow: hidden;
  div {
    max-width: 100%;
  }
}

.topics-kicker {
  margin-bottom: 3px !important;
  margin-top: 12px !important;
}

.booklist-left {
  padding-bottom: 20px;
  div.intro {
    border-top: 1px solid $swab-gray;
    border-bottom: 1px solid $swab-gray;
    padding-top: 15px;
    margin-bottom: 20px;
  }
}
.booklist-section {
  .left {
    float: left;
    width: 40%;
    img {
      width: calc(100% - 1em);
    }
  }
}

.booklist-right {
  margin-top: 5px;
}

/* Sidebar */
.region-sidebar-first {
  h2.block-title {
    text-align: center;
  }
}

/* show more button */
.reveal-button {
  &:last-letter {
    color: $swab-red;
  }
}

/* DIY Science Pages */

.article-science-topic {
  .field-name-field-science-image {
    float: left;
    width: 40% !important;
    img {
      max-width: 100%;
    }
  }
  .field-name-field-science-intro {
    .imgleft {
      float: right;
      width: calc(60% - 30px);
      .left {
        float: left;
      }
    }
  }
  .jump-links {
    float: right;
    width: calc(60% - 60px);
  }
}


// iframe fix
.view-related-videos {
  .views-field-field-image {
    .field-content {
      position: relative;
      height: 0;
      overflow: hidden;
      padding-bottom: 58.25%;
      // margin-left: -15px;
      // margin-right: -15px;
      margin-bottom: 10px;
      max-width: calc(100% + 30px) !important;
    }
    iframe {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.bookbox img {
  width: 100%;
  max-width: 100%;
}



/* Responsive Styles */

/* Tablet landscape */
@media only screen and (min-width:769px) and (max-width:1024px) {
  .container {
    max-width: 100% !important;
  }
  .negative-margin {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .image-strip {
    max-width: calc(100vw) !important;
    img {
      width: 100vw;
    }
  }
}

.intro-text {
  text-align: center;

  h1 {
    margin-top: 0;

    @include breakpoint($tablet-portrait-min) {
      font-size: 3.75rem;
    }
  }

  p {
    font-size: 1.4rem;
    color: $swab-red;
  }
}

/* Special image strip breakpoint */

@media only screen and (max-width:959px) {
  .image-strip {
    background: none;
    margin-left: 0px !important;
    margin-right: 0px !important;
    width: auto!important;
    max-width: 100% !important;
    img {
      width: 100%;
    }
  }
}

/* Tablet portrait */
@media only screen and (min-width:481px) and (max-width:768px) {
  #header-wrapper .ricky-rocket {
    width: 25%;
  }

  #header-wrapper {
    .header-text {
      width: 75%;
      p {
        font-size:1em;
      }
    }
  }

  .view-home-panel-images {
    margin-left: -15px;
    margin-right: -15px;
  }

  .container {
    max-width: 100% !important;
  }
  #nav-wrapper .menu li.expanded ul {
    display: none !important;
  }
  #nav-wrapper .menu li.expanded > a:after {
    display: none;
  }
  .one-third,
  .pane-bundle-featured-content,
  .pane-bundle-featured-video {
    width: 100%;
    padding: 10px 0;
  }
  .home-row.row-first {
    padding-top: 0px !important;
  }
  .intro-blocks img {
    max-width: 25%;
    float:left;
    margin: 0px;
    padding-right: 25px;
  }
  .intro-blocks h2,
  .intro-blocks p {
    max-width: 75%;
    float:left;
    margin: 0px;
    clear: none;
  }
  .button {
    width: 75%;
  }
  .home-row {
    padding: 50px 30px;
  }
  .negative-margin {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .bottom-strip {
    font-size: 1.5em;
  }
  .contentbox {
    .row {
      .box {
        width: calc(50% - 1em);
        &:nth-child(even) {
          margin-right: 0px;
        }
        &:nth-child(odd) {
          clear: both;
        }
      }
    }
  }
  .booklist-section {
    .left,
    .fRight {
      width: 100% !important;
    }
  }

  /* DIY Science Pages */
  .article-science-topic {
    .field-name-field-science-image {
      width: 100% !important;
      clear: both;
    }
    .field-name-field-science-intro {
      .imgleft {
        float: left;
        width: 100%;
      }
    }
    .jump-links {
      float: left;
      width: 100%;
    }
  }
  // iframe fix
  .view-related-videos {
    .views-field-field-image {
      .field-content {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}

/* Smartphone landscape */
@media only screen and (max-width:480px) {
  /* Mobile Menu */
  #mobile-menu {
    display: block;
    background-color: $swab-dark-blue;

  }
  #nav-wrapper {
    max-height: 0;
    overflow: hidden;
    transition: all 0.5s ease;
    .menu li {
      text-align: left;
    }
    .three-stars {
      background: none;
      padding-left: 0px !important;
    }
  }
  .show-nav #nav-wrapper {
    max-height: 99em;
  }
  #nav-wrapper .menu li.expanded ul {
    display: none !important;
  }
  #nav-wrapper .menu li.nolink {
    border-top: 0px;
    & > a {
      display: none;
      height: 0;    }
    ul {
      display: block !important;
      position: relative;
      top: 0;
      padding: 0;
      background-color: $swab-dark-blue;
      border-top: 1px solid $swab-light-blue;
      #nav-wrapper .menu li.expanded ul
      li {
        width: 100%;
        a {
          height: 3em !important;
          line-height: 3em !important;
          color: white;
        }

      }
    }
  }
  #nav-wrapper .menu li ul li a {
    color: white !important;
  }
  #nav-wrapper .menu li.expanded > a:after {
    display: none;
  }

  .ricky-rocket {
    top: 0;
  }

  #header-wrapper {
    position: relative;
    .header-text p {
      font-size: 0.75em;
      margin-top: 0px;
      padding-left: 20px;
    }
  }

  #block-block-23 {
    // max-width: 25px !important;
    margin-right:20px;
  }

  /* Search Styles */

  #block-block-23 {
    margin: 15px 25px 0px !important;
    width: 180px !important;
  }

  #cse-search-form {
    width:180px;
    display: block;
    background: white;
  }

  #search-toggle {
    display: none;
  }

.show-search {
  #search-toggle {
    display: none;
  }
}

  .container {
    max-width: 100% !important;
  }
  .one-third,
  .pane-bundle-featured-content,
  .pane-bundle-featured-video {
    width: 100%;
    padding: 10px 0px !important;
  }
  .home-row.row-first {
    padding-top: 0px !important;
  }
  .negative-margin {
    margin-left: 0px;
    margin-right: 0px;
    width: 100%;
  }
  .button {
    width: 100%;
  }
  .bottom-strip {
    font-size: 1.5em;
  }
  .view-home-panel-images {
    margin-left: -15px;
    margin-right: -15px;
  }
  // .contentbox {
  //   .row {
  //     .box {
  //       width: 100%;
  //       margin-right: 0px;
  //     }
  //   }
  // }
  .contentbox {
    .row {
      .box {
        width: calc(50% - 1em);
        font-size: 1.25em;
        &:nth-child(even) {
          margin-right: 0px;
        }
        &:nth-child(odd) {
          clear: both;
        }
      }
    }
  }
  /* DIY Science Pages */
  .article-science-topic {
    .field-name-field-science-image {
      width: 100% !important;
      clear: both;
    }
    .field-name-field-science-intro {
      .imgleft {
        float: left;
        width: 100%;
      }
    }
    .jump-links {
      float: left;
      width: 100%;
    }
  }
  // iframe fix
  .view-related-videos {
    .views-field-field-image {
      .field-content {
        margin-left: 0px;
        margin-right: 0px;
      }
    }
  }
}

/* Smartphone portrait */
@media only screen and (max-width:320px) {
  .container {
    max-width: 100% !important;
  }
  .one-third,
  .pane-bundle-featured-content,
  .pane-bundle-featured-video {
    width: 100%;
  }
  .button {
    width: 100%;
  }
}

// Blog and topics blog changes

.field-name-field-blog-tags {
  border-top: 1px solid #ccc;
  padding: 15px 0;
  .field-label {
    font-weight: normal !important;
    padding-bottom: 8px !important;
  }
}

.middle-col {
  float: left;
  width: 29%;
  margin-right: 3%;
  .view-related-videos {
    float: none !important;
    width: 100%;
  }
  .view-related-blogs {
    padding-top: 20px;
    border-top: 1px solid #ccc;
    h3 {
      padding: 10px 0;
    }
  }
  @media only screen and (max-width:480px) {
    width: 100%;
    margin-right: 0;
    .view-related-videos {
      padding-top: 20px;
    }
  }
}

.view-related-blogs .views-field.views-field-title {
  background: none !important;
  background-color: white !important;
  margin-bottom: 5px;
}

.field-name-field-featured-image img {
  width: 100%;
  margin-bottom: 10px;
}

.homepage-carousel {
  min-height: 220px;
  height: 100%;
  height: auto;
  position: relative;

  .article-homepage-carousel-slide {
    margin: 0;
  }

  .slick-slide {
    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .slick-next,
  .slick-prev {
    position: absolute;
    z-index: 15;
    width: 32px;
    border-radius: 50%;
    transition: all .25s ease;
    background: rgba(0,0,0,0);
    height: 64px;
    opacity: .5;
    color: transparent;
    text-decoration: none;
    display: block;
    text-indent: -999px;
    overflow: hidden;

    &::before,
    &::after {
      content: "";
      border-radius: 1px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 3px;
      height: 16px;
      background: #fff;
    }

    &::before {
      transform: rotate(45deg);
      top: 10px;
    }

    &::after {
      transform: rotate(-45deg);
      top: -10px;
    }

    &:hover,
    &:focus {
      opacity: 1;
      background: rgba(0,0,0,.5);
      text-decoration: none;
    }
  }

  .slick-next {
    border-radius: 32px 0 0 32px;
    right: 0;

    @include breakpoint($desktop-medium-min) {
      right: 10px;
      border-radius: 50%;
      width: 64px;
    }
  }

  .slick-prev {
    border-radius: 0 32px 32px 0;
    left: 0;

    &::before {
      transform: rotate(-45deg);
    }

    &::after {
      transform: rotate(45deg);
    }

    @include breakpoint($desktop-medium-min) {
      left: 10px;
      border-radius: 50%;
      width: 64px;
    }
  }

  .slick-autoplay-toggle-button {
    position: absolute;
    bottom: -10px;
    left: 0;
    right: 0;
    margin: auto;
    z-index: 5;
    text-align: center;
    background: $swab-yellow;
    color: $swab-black;
    max-width: 30px;
    height: 30px;
    border: 2px solid $swab-black;
    border-radius: 18px;
    padding: 0;
    white-space: nowrap;
    overflow: hidden;
    transition: all 0.3s ease;
    font-size: 0.875rem;
    line-height: 28px;
    font-weight: 700;
    opacity: 1;
    text-transform: uppercase;

    .slick-play-icon,
    .slick-pause-icon {
      width: 28px;
      height: 28px;
      display: inline;
      padding-left: 7px;
      padding-right: 8px;
      line-height: 29px;
      text-align: center;
      font-size: 0.8rem;
      vertical-align: top;
    }

    .slick-play-icon {
      &::before {
        content: "\f04b";
        font-family: "Font Awesome 6 Pro";
        font-weight: bold;
        position: relative;
        color: $swab-black;
        left: 1px;
      }
    }

    .slick-pause-icon {
      &::before {
        content: "\f04c";
        font-family: "Font Awesome 6 Pro";
        font-weight: bold;
        color: $swab-black;
      }
    }

    .slick-sr-only {
      @include visually-hidden;

      &.slick-pause-text,
      &.slick-play-text {
        @include visually-hidden-off;
        clip-path: none !important;
        clip: unset !important;
        font-weight: bold;
        text-transform: uppercase;
        color: $swab-black;
        display: inline;
        line-height: 28px;
      }
    }

    &:hover,
    &:focus {
      max-width: 100px;
      padding-right: $horiz-gutter*0.125;
    }

    @include breakpoint($desktop-medium-max) {
      bottom: -15px;
    }
  }

  .article-homepage-carousel-slide {
    .field-name-field-carousel-image {
      img {
        max-height: 600px;
        object-fit: cover;
        width: 100%;
      }
    }

    .field-slide-text {
      display: block;
      line-height: 1.6;

      > *:last-child {
        margin-bottom: 0;
      }
    }

    .field-carousel-text {
      font-weight: 400;
      text-align: center;
      line-height: 1;
      z-index: 2;
      color: $swab-black;
      font-family: $verveine;
      font-size: 2rem;
    }

    .carousel-link {
      @include transition;
      text-decoration: none;
      color: $swab-black;

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 100%;
      }

      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    .carousel-text {
      display: block;
    }

    @include breakpoint($tablet-portrait-min) {
      .carousel-text {
        padding-left: 70px;
        padding-right: 70px;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
      }

      .field-carousel-text {
        font-size: 5.125rem;
        position: static;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        height: 100%;
        z-index: 2;
      }

      .blob {
        z-index: -1;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        display: block;

        path {
          fill: #a4f3ed;
        }
      }

      // Text on Right
      &.position--right {
        .field-carousel-text {
          margin-left: auto;
          margin-right: 0;
        }

        .blob {
          right: 70px;
          transform: translate(8%, 0);
        }
      }

      // Text on Left
      &.position--left {
        .blob {
          left: 70px;
        }
      }
    }

    @include breakpoint($desktop-min) {
      .field-carousel-text {
        width: 360px;
      }

      .blob {
        width: 400px;
        height: auto;
      }
    }

    @include breakpoint($tablet-portrait-min $desktop-max) {
      .field-carousel-text {
        width: 220px;
        font-size: 3rem;
      }

      .blob {
        width: 250px;
        height: auto;
      }
    }

    @include breakpoint($tablet-portrait-max) {
      .blob {
        display: none;
      }

      .field-carousel-text {
        padding: $horiz-gutter*0.5 $horiz-gutter $horiz-gutter;
        background-color: #a4f3ed;
      }
    }
  }
}

.front {
  .region-highlighted {
    .block-inner {
      margin: 0;
    }
  }
}

.block-swab-share-print {
  .block-inner {
    margin: 0;
  }

  ul {
    display: flex;
    flex-direction: row;

    li {
      margin: 0;
      padding: 0;
      width: 44px;
      height: 44px;
    }

    a {
      @include transition;

      width: 44px;
      height: 44px;
      margin: 0;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      font-size: 1.4rem;
      text-decoration: none;

      i {
        color: $swab-dark-blue;
      }

      &:hover,
      &:focus {
        text-decoration: none;

        i {
          color: $swab-red;
        }
      }
    }
  }

  @include breakpoint($desktop-min) {
    width: 45px;
    position: fixed;
    top: 40%;
    left: 0;
    z-index: 15;

    ul {
      flex-direction: column;

      a {
        outline-color: rgba(0,0,0,0);
        border: 2px solid;
        border-color: $swab-black;
        background: #fafafa;

        &:hover,
        &:focus {
          background-color: $swab-blue;

          i {
            color: #fff;
          }
        }
      }
    }
  }
}

#page {
  .container {
    position: initial;
  }
}

.region-secondary-content {
  margin-bottom: $horiz-gutter*2.5;
  background-image: url("../images/swab-cloudsbkgrd.png");
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;

  .block {
    padding: 0;
    margin: 0;
  }

  img {
    width: 600px;
    display: block;
    margin: auto;
  }

  @include breakpoint($desktop-medium-min) {
    .block {
      @include layout-container;
      margin-bottom: 0;
      padding: 0;
    }

    img {
      width: 800px;
    }
  }

  @include breakpoint($desktop-large-min) {
    img {
      width: 1100px;
    }
  }
}

img[style="float:left"] {
  padding-right: 25px;
  padding-bottom: 25px;
}

img[style="float:right"] {
  padding-left: 25px;
  padding-bottom: 25px;
}
