#block-panels-mini-footer-menu {
  font-size: 1rem;
  padding: $vert-gutter 0 $vert-gutter/2;
  a {
    text-decoration: none !important;
    display: inline-block;
    &:hover,
    &:focus {
      text-decoration: underline !important;
    }
    &.highlight {
      color: $swab-bright-yellow;
      padding-bottom: .5em;
    }
    &.nolink {
      pointer-events: none;
      &:hover,
      &:focus {
        text-decoration: none !important;
      }
    }
  }
  h2 {
    font-size: 1rem;
    color: $swab-bright-yellow;
    padding: 0 0 .5em;
    margin: 0;
    font-family: $body-font;
  }
  li {
    list-style-type: none;
    list-style-image: none;
    padding-bottom: .5em;
  }
  .column {
    display: block;
  }
  .space-top {
    padding-top: $vert-gutter*0.75;
  }
  @include breakpoint($phone-min $desktop-small-max) {
    .column {
      width: 50%;
      padding-top: $vert-gutter*0.75;
      &:nth-child(2),
      &:nth-child(3) {
        margin-left: -5px;
      }
    }
  }
  @include breakpoint($phone-max) {
    .column {
      width: 100%;
      & + .column {
        padding-top: $vert-gutter*0.75;
        margin-left: -5px;
      }
    }
  }
}
